<template>
	<!-- Image -->
	<img
		draggable="false"
		ref="imgElement"
		v-if="attributes.readOnly && imgPath"
		alt=""
		loading="lazy"
		:src="imgPath"
		:class="{ 'keep-aspect-ratio': attributes.keepAspectRatio }"
		:style="{ objectFit: attributes.objectFit }"
	/>
	<!-- Signature -->
	<canvas
		v-if="!attributes.readOnly || attributes.type === 'DR'"
		ref="signElement"
		:width="controlWidth"
		:height="controlHeight"
		:style="{
			position: attributes.type === 'DR' && 'absolute',
			backgroundColor: attributes.type === 'DR' && 'transparent',
		}"
		class="xone-signature"
	></canvas>
	<div v-if="!attributes.readOnly" class="xone-clear-signature">
		<button @click="clearCanvas()">✕</button>
	</div>
</template>

<script>
import { inject, nextTick, onMounted, PropType, ref, Ref, watch, watchEffect } from "vue";
import { PropAttributes, xoneAttributesHandler } from "../../composables/XoneAttributesHandler";
import { XoneDataObject } from "../../composables/appData/core/XoneDataObject";
import SignaturePad from "signature_pad";
import { getImagePath } from "../../composables/helperFunctions/ImageHelper";
import { XoneControl, XoneView } from "../../composables/XoneViewsHandler";
export default {
	props: {
		/** xoneDataObject
     @type {PropType<XoneDataObject>} 
     */
		xoneDataObject: { type: Object, required: true },
		/** attributes
		 * @type { PropType<PropAttributes>}
		 */
		attributes: { type: Object, required: true },
		controlWidth: { type: Number, default: 0 },
		controlHeight: { type: Number, default: 0 },
		containerWidth: { type: Number, default: 0 },
		containerHeight: { type: Number, default: 0 },
	},
	setup(props) {
		/**
		 * prop model value
		 * @type {Ref<any>}
		 */
		let propValue = ref();

		// Get appData model value
		watchEffect(() => {
			if (props.xoneDataObject?.model) propValue.value = props.xoneDataObject?.model[props.attributes.name];
		});

		const imgPath = ref();

		/**
		 * imgElement
		 * @type {Ref<HTMLElement>}
		 */
		const imgElement = ref();

		const fitSizeToContainer = () => {
			const doFit = () => {
				xoneAttributesHandler.fitHeightToContainer(props.attributes, imgElement);
				xoneAttributesHandler.fitWidthToContainer(props.attributes, imgElement);
			};
			setTimeout(() => doFit(), 100);
			doFit();
		};

		watchEffect(async () => {
			if (propValue.value) {
				imgPath.value = (await getImagePath(propValue.value, "files")) || propValue.value;
			}
			if (props.attributes.image && props.attributes.image !== "") {
				imgPath.value = (await getImagePath(props.attributes.image, "icons")) || props.attributes.image;
			}
			fitSizeToContainer();
		});

		/**
		 * sign
		 * @type {Ref<HTMLElement>}
		 */
		const signElement = ref();

		/**
		 * signaturePad
		 * @type {SignaturePad}
		 */
		let signaturePad;

		onMounted(() => {
			if (!signElement.value) return;
			signElement.value.setAttribute("swipeable", false);
			signaturePad = new SignaturePad(signElement.value);
		});

		const clearCanvas = () => {
			if (!signaturePad) return;
			signaturePad.clear();
		};

		/**
		 * Last breadcrumb in stack
		 * @type {ComputedRef<import('../../composables/AppDataHandler').Breadcrumb>}
		 */
		const lastBreadcrumb = inject("lastBreadcrumb");

		/**
		 * breadcrumbId
		 * @type {string}
		 */
		const breadcrumbId = inject("breadcrumbId");

		watchEffect(() => {
			if (breadcrumbId === lastBreadcrumb?.value?.id) fitSizeToContainer();
		});

		nextTick(() => {
			watch(
				() => props.containerWidth,
				() => fitSizeToContainer()
			);
			watch(
				() => props.containerHeight,
				() => fitSizeToContainer()
			);
		});

		// if selected group is current group fit size to container
		/**
		 * groupId
		 * @type {number}
		 */
		const groupId = inject("groupId");

		/**
		 * activeGroup
		 * @type {{activeGroup:Ref<string>}}
		 */
		const { activeGroup } = inject("groupHandler");

		watch(
			() => activeGroup.value,
			(newValue) => {
				if (groupId !== newValue) return;
				fitSizeToContainer();
			}
		);

		/**
		 * xoneView
		 * @type {XoneView}
		 */
		const xoneView = inject("xoneView");

		const xoneControl = new XoneControl(props.attributes.name, imgElement.value || signElement.value);
		onMounted(() => {
			xoneControl.clearDrawing = clearCanvas;

			// Add control to view
			xoneView.addControl(xoneControl);
		});

		return { imgElement, imgPath, signElement, clearCanvas };
	},
};
</script>

<style scoped>
/* Image */
img,
canvas {
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-position: center;
	animation: fadeIn 0.3s;
}

.keep-aspect-ratio {
	object-fit: contain;
}

.xone-signature {
	display: block;
	cursor: pointer;
	background-color: white;
	border-radius: 5px;
	box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px rgba(0, 0, 0, 0.14), 0 1px 10px rgba(0, 0, 0, 0.12);
}

.xone-clear-signature {
	position: absolute;
	top: 0;
	right: 0;
}

button {
	padding: 10px;
}
</style>
