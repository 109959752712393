<template>
  <div id="speedometer">
    <div style="display: none"><img id="sprite" src="assets/icons.svg" /></div>
    <canvas
      ref="canvasElement"
      width="425"
      height="210"
      :style="{ width: elementWidth + 'px', height: elementHeight + 'px' }"
    ></canvas>
  </div>
</template>

<script>
import { HTMLCanvasElement, Ref, ref, computed, onMounted, watch } from "vue";
import {
  initCanvas,
  draw,
} from "../../../composables/public/modules/speedometer/speedometer";
import { XoneDataObject } from "../../../composables/appData/core/XoneDataObject";
import {
  getAppPath,
  getImagePath,
  getImagePathAndSize,
} from "../../../composables/helperFunctions/ImageHelper";

export default {
  name: "Contents",
  props: {
    /**
     * xoneDataObject
     * @type {PropType<XoneDataObject>}
     * */
    xoneDataObject: { type: Object, required: true },
    controlHeight: { type: Number, default: 0 },
    controlWidth: { type: Number, default: 0 },
    /** attributes
		 @type {PropType<import('../../../composables/XoneAttributesHandler').PropAttributes>}
		*/
    attributes: { type: Object, required: true },
  },
  setup(props) {
    /** @type {Ref<HTMLCanvasElement>} */
    const canvasElement = ref();

    const elementWidth = computed(() => {
      if (props.controlWidth / 2 > props.controlHeight)
        return props.controlHeight * 2;
      return props.controlWidth;
    });

    const elementHeight = computed(() => {
      if (props.controlWidth / 2 > props.controlHeight)
        return props.controlHeight;
      return props.controlWidth / 2;
    });

    watch(
      () => props.xoneDataObject.model[props.attributes.name],
      () => {
        reDraw();
      }
    );
    watch(
      () => props.attributes.interval,
      () => reDraw()
    );
    watch(
      () => props.attributes.minValue,
      () => reDraw()
    );
    watch(
      () => props.attributes.maxValue,
      () => reDraw()
    );
    watch(
      () => props.attributes.foreColor,
      () => reDraw()
    );
    watch(
      () => props.attributes.bgColor,
      () => reDraw()
    );
    watch(
      () => props.attributes.startAngle,
      () => reDraw()
    );
    watch(
      () => props.attributes.endAngle,
      () => reDraw()
    );
    watch(
      () => props.attributes.offset,
      () => reDraw()
    );

    onMounted(async () => {
      if (!canvasElement.value) return;
      initCanvas(canvasElement.value);
      watch(
        () => props.attributes.image,
        async () => await resolveImage()
      );
      await resolveImage();
      reDraw();
    });

    const imgPath = ref(
      props.attributes.image?.includes("icons") ||
        props.attributes.image?.includes("files")
        ? props.attributes.image
        : `${getAppPath()}/icons/${props.attributes.image}`
    );

    let image;

    const resolveImage = async () => {
      const imagePathAndSize = await getImagePathAndSize(
        props.attributes.image,
        "icons"
      );
      imgPath.value = imagePathAndSize ? imagePathAndSize.path : "";
      image = imagePathAndSize ? imagePathAndSize.image : null;
    };

    const reDraw = () => {
      draw(
        props.xoneDataObject.model[props.attributes.name],
        props.attributes.startAngle,
        props.attributes.endAngle,
        props.attributes.offset,
        Math.round(
          props.xoneDataObject.model[props.attributes.name] *
            props.attributes.maxValue
        ),
        {
          left: false,
          right: false,
        },
        {
          // main circle
          dippedBeam: 0,
          brake: 0,
          drift: 0,
          highBeam: 0,
          lock: 0,
          seatBelt: 0,
          engineTemp: 0,
          stab: 0,
          abs: 0,
          // right circle
          gas: 0,
          trunk: 0,
          bonnet: 0,
          doors: 0,
          // left circle
          battery: 0,
          oil: 0,
          engineFail: 0,
        },
        props.attributes.bgColor,
        props.attributes.foreColor,
        props.attributes.image,
        image
      );
    };

    return { canvasElement, elementWidth, elementHeight };
  },
};
</script>
