<template>
	<div v-if="isLoading" class="xone-loader"><div></div></div>
	<canvas v-show="!isLoading" :id="`chart-${breadcrumbId}-${attributes.name}${objectIndex}`"></canvas>
</template>

<script>
import { inject, ref, watchEffect, Ref, PropType, onUnmounted, nextTick, onMounted, watch } from "vue";

import { PropAttributes } from "../../../composables/XoneAttributesHandler";
import { XoneDataObject } from "../../../composables/appData/core/XoneDataObject";
import { XoneDataCollection } from "../../../composables/appData/core/XoneDataCollection";
// import { hexToRgbA } from "../../../composables/helperFunctions/ChartsHelper";
import { XoneControl, XoneView } from "../../../composables/XoneViewsHandler";
import XmlNode from "../../../composables/appData/Xml/JSONImpl/XmlNode";
import { loadChart } from "../../../composables/helperFunctions/ChartHelper";

export default {
	name: "ChartPie",
	props: {
		/**
		 * xoneDataObject
		 * @type {PropType<XoneDataObject>}
		 * */
		xoneDataObject: { type: Object, required: true },
		/**
		 * attributes
		 * @type { PropType<PropAttributes>}
		 */
		attributes: { type: Object, default: null, required: true },
		controlWidth: { type: Number, default: 0 },
		controlHeight: { type: Number, default: 0 },
	},
	setup(props) {
		/**
		 * breadcrumbId
		 * @type {string}
		 */
		const breadcrumbId = inject("breadcrumbId");

		/**
		 * objectInfo
		 * @type {import('../../../composables/AppDataHandler').Objectinfo}
		 */
		const objectInfo = inject("objectInfo");

		const objectIndex = objectInfo.isContents ? objectInfo.recordIndex : "";

		/**
		 * Contents
		 * @type {Ref<XoneDataCollection>}
		 */
		const contents = ref();

		/**
		 * xoneView
		 * @type {XoneView}
		 */
		const xoneView = inject("xoneView");

		/** @type {HTMLCanvasElement} */
		let ctx;

		const isLoading = ref(true);

		let refreshTimeOut;
		let refreshTime = 100;

		const refresh = async () => {
			if (!ctx) return;

			isLoading.value = true;

			if (refreshTimeOut) clearTimeout(refreshTimeOut);

			refreshTimeOut = setTimeout(async () => {
				if (!contents.value) contents.value = await props.xoneDataObject.getContents(props.attributes.contents);

				// Load contents data
				await contents.value.loadAll(false);

				/**
				 * contents data
				 * @type {Ref<Array<XoneDataObject>>}
				 */
				const data = {
					labels: [],
					datasets: [],
				};

				// Fill chart data
				let chartSerieTitle;
				let chartName;
				let chartTitle;
				let serieColor = "";

				/**
				 * m_xmlNode
				 * @type {{m_xmlNode:XmlNode}}
				 */
				const { m_xmlNode } = contents.value;

				m_xmlNode.SelectNodes("prop").forEach((/** @type {XmlNode} */ e) => {
					// Chart serie title
					if (e.getAttrValue("chart-serie-title") === "true") chartSerieTitle = e.getAttrValue("name");

					// Chart value
					if (e.getAttrValue("chart-value") === "true") {
						chartName = e.getAttrValue("name");
						chartTitle = e.getAttrValue("title");
					}

					// Chart serie color
					if (e.getAttrValue("chart-serie-color") === "true") serieColor = e.getAttrValue("name");
				});

				// Clear data
				data.datasets = [];
				data.labels = [];

				const dataset = { label: chartTitle };

				dataset.data = [];
				dataset.backgroundColor = [];
				dataset.borderColor = [];
				dataset.borderWidth = 1;

				// Create data
				for (let i = 0; i < contents.value.length; i++) {
					/**
					 * rowDataObject
					 * @type {XoneDataObject}
					 */
					const rowDataObject = await contents.value.get(i);

					// Chart Serie Title
					if (chartSerieTitle) data.labels.push(rowDataObject[chartSerieTitle]);

					dataset.data.push(rowDataObject[chartName]);
					dataset.backgroundColor.push(rowDataObject[serieColor]);
				}
				data.datasets.push(dataset);

				createChart(data);
				isLoading.value = false;
			}, refreshTime);
		};

		let myChart;
		const createChart = async (data) => {
			while (groupId !== activeGroup.value) await new Promise((resolve) => setTimeout(() => resolve(), 150));
			if (!myChart) {
				myChart = new Chart(ctx, {
					type: props.attributes.viewMode === "piechart" ? "pie" : "doughnut",
					data: data,
					options: {
						cutoutPercentage: props.attributes.viewMode === "piechart" ? 0 : 70,
						// animation: false,
						maintainAspectRatio: false,
						responsive: true,
						// animation: { duration: 1000 },
					},
				});
				// Vamos a quitar la animacion inicial y se la asignamos una vez existen los datos, así no se bloquea el ui si existen más cargas de contents en pantalla
				myChart.options.animation = {
					duration: 1000,
					easing: "easeOutQuart",
				};
			} else {
				myChart.data = data;
				myChart.update();
			}
			// En lugar de la animación del chart, hacemos que aparezca con un fadeIn
			// ctx.animate(
			// 	[
			// 		// keyframes
			// 		{ opacity: 0.1 },
			// 		{ opacity: 1 },
			// 	],
			// 	{
			// 		// timing options
			// 		duration: 500,
			// 	}
			// );
		};

		/**
		 * Group Id
		 * @type {string}
		 */
		const groupId = inject("groupId");

		/**
		 * Group active
		 * @type {{activeGroup:Ref<string>}}
		 */
		const { activeGroup } = inject("groupHandler");

		// Get chart element
		onMounted(() => {
			loadChart(async () => {
				await new Promise((resolve) => setTimeout(() => nextTick().then(() => resolve()), 500));

				ctx = document.getElementById(`chart-${breadcrumbId}-${props.attributes.name}${objectIndex}`);
				// Add control to view
				const xoneControl = new XoneControl(props.attributes.name);
				xoneControl.refresh = refresh;
				xoneView.addControl(xoneControl);
				watchEffect(async () => {
					if (contents.value || groupId !== activeGroup.value) return;
					refresh();
				});
			});
		});

		let updateChartTimeout;
		const updateChart = async () => {
			if (!myChart) return;
			if (updateChartTimeout) clearTimeout(updateChartTimeout);
			updateChartTimeout = setTimeout(async () => {
				await nextTick();
				createChart();
			}, 250);
		};

		watch(
			() => props.controlWidth,
			() => updateChart()
		);
		watch(
			() => props.controlHeight,
			() => updateChart()
		);

		// Clear contents
		onUnmounted(() => {
			if (contents.value) contents.value.clear();
		});

		return { breadcrumbId, objectIndex, isLoading };
	},
};
</script>

<style scoped>
canvas {
	position: relative;
	width: calc(var(--contents-width)-4px);
	height: calc(var(--contents-height)-4px);
	max-height: var(--contents-max-height);
	animation: fadeIn 0.5s;
}
</style>
