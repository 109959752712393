<template>
	<div v-if="routeBreadcrumbs && routeBreadcrumbs.length !== 0" class="xone-breadcrumb">
		<template v-for="breadcrumb in routeBreadcrumbs" :key="breadcrumb.id"
			><div class="xone-breadcrumb-item">
				<div v-if="breadcrumb !== routeBreadcrumbs[0]" :style="{ fontSize: attributes.breadcrumbFontSize }">
					&nbsp;&nbsp;/&nbsp;&nbsp;
				</div>
				<div
					@click="onBreadcrumbClick(breadcrumb)"
					:style="{
						color: getBreadcrumbColor(breadcrumb),
						fontSize: getBreadcrumbFontSize(breadcrumb),
						cursor: breadcrumb !== lastBreadcrumb ? 'pointer' : 'auto',
					}"
				>
					{{ breadcrumb.title || breadcrumb.name }}
				</div>
			</div></template
		>
	</div>
</template>

<script>
import { inject, ComputedRef, PropType } from "vue";
import { useRouter } from "vue-router";

export default {
	name: "Breadcrumb",
	props: {
		/**
		 * attributes
		 * @type { PropType<import('../../composables/XoneAttributesHandler').PropAttributes>}
		 */
		attributes: { type: Object, default: null, required: true },
	},
	setup(props) {
		const router = useRouter();

		/**
		 * @type {ComputedRef<Breadcrumb[]>}
		 */
		const routeBreadcrumbs = inject("routeBreadcrumbs");

		/**
		 * @type {ComputedRef<import('../../composables/AppDataHandler').Breadcrumb>}
		 */
		const lastBreadcrumb = inject("lastBreadcrumb");

		return {
			routeBreadcrumbs,
			lastBreadcrumb,
			onBreadcrumbClick: (breadcrumb) => {
				router.push({
					name: "Coll",
					query: { id: breadcrumb.id },
				});
			},
			getBreadcrumbColor: (breadcrumb) => {
				return breadcrumb === lastBreadcrumb.value
					? props.attributes.breadcrumbSelectedForeColor || props.attributes.breadcrumbForeColor
					: props.attributes.breadcrumbForeColor;
			},
			getBreadcrumbFontSize: (breadcrumb) => {
				return breadcrumb === lastBreadcrumb.value
					? props.attributes.breadcrumbSelectedFontSize || props.attributes.breadcrumbFontSize
					: props.attributes.breadcrumbFontSize;
			},
		};
	},
};
</script>

<style scoped>
.xone-breadcrumb,
.xone-breadcrumb-item {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}
</style>
