<template>
	<teleport v-if="state.visible" to="body">
		<div class="xone-msgbox">
			<!-- Standard Msgbox -->
			<div class="xone-msgbox-box" v-if="!state.isCustomMsgbox">
				<div class="xone-msgbox-title">{{ state.title }}</div>
				<div class="xone-msgbox-text">{{ state.msg }}</div>
				<div class="xone-msgbox-options">
					<button ref="btnOkElement" @click="onClick(true)">{{ state.btn1 }}</button>
					<button v-if="state.btn2 !== ''" @click="onClick(false)">
						{{ state.btn2 }}
					</button>
				</div>
			</div>
			<!-- Custom Msgbox -->
			<div class="xone-custom-msgbox" v-if="msgboxBreadcrumb">
				<Coll :breadcrumb="msgboxBreadcrumb" :objectInfo="objectInfo"></Coll>
			</div>
		</div>
	</teleport>
</template>

<script>
import { computed, inject, reactive, Ref, ComputedRef, ref, nextTick } from "vue";

import Coll from "../Coll.vue";
import { setCustomMsgBoxCallback, setMsgBoxCallback } from "../../composables/XoneUI";
import { XoneDataObject } from "../../composables/appData/core/XoneDataObject";
import AppDataHandler, { Breadcrumb } from "../../composables/AppDataHandler";

export default {
	components: {
		Coll,
	},
	setup() {
		const state = reactive({
			isCustomMsgbox: false,
			title: "",
			msg: "",
			flags: 0,
			btn1: "",
			btn2: "",
			response: null,
			visible: false,
		});

		//
		// Standard Msgbox

		setMsgBoxCallback(async (message = "", title = "", flags = 0) => {
			state.isCustomMsgbox = false;
			state.title = title;
			state.msg = message;
			state.flags = flags;
			switch (flags) {
				case 0:
					state.btn1 = "Ok";
					state.btn2 = "";
					break;
				case 1:
					state.btn1 = "Sí";
					state.btn2 = "No";
					break;
				case 4:
					state.btn1 = "Ok";
					state.btn2 = "Cancel";
					break;
			}
			state.response = null;
			state.visible = true;
			await nextTick();
			btnOkElement.value.focus();
			while (!state.response) await new Promise((resolve) => setTimeout(() => resolve(), 100));

			state.visible = false;

			return state.response;
		});

		const onClick = (isAccepted) => {
			switch (state.flags) {
				case 0:
					return (state.response = 10);
				case 1:
					return (state.response = isAccepted ? 1 : 2);
				case 4:
					return (state.response = isAccepted ? 6 : 7);
				default:
					return (state.response = 0);
			}
		};

		//
		// Custom MsgBox

		/**
		 * XOne Web Layout
		 * @type {ComputedRef<Breadcrumb>}
		 */
		const msgboxBreadcrumb = computed(() => {
			const layout = AppDataHandler.getBreadcrumbs().filter((e) => e.isMsgbox);
			return layout.length !== 0 ? layout[0] : null;
		});

		const objectInfo = {
			isContents: false,
			isMsgBox: true,
			onMsgBoxOptionSelected: (res) => {
				AppDataHandler.removeBreadcrumb(msgboxBreadcrumb.value.id);
				return (state.response = !isNaN(Number(res)) ? Number(res) : (state.response = res));
			},
		};

		setCustomMsgBoxCallback(async (dataObject) => {
			state.response = null;

			if (!(dataObject instanceof XoneDataObject)) return;

			AppDataHandler.pushXoneDataObject(dataObject, "Msgbox", false, true);

			state.isCustomMsgbox = true;
			state.visible = true;

			while (!state.response) await new Promise((resolve) => setTimeout(() => resolve(), 100));

			state.visible = false;

			return state.response;
		});

		/**
		 * Window Size
		 * @type {{containerWidth: Ref<number>|ComputedRef<number>, containerHeight: Ref<number>|ComputedRef<number>}}
		 */
		const { containerWidth, containerHeight } = inject("containerSize");

		/** @type {Ref<HTMLButtonElement>} */
		const btnOkElement = ref();

		return {
			state,
			onClick,
			msgboxBreadcrumb,
			containerWidth,
			containerHeight,
			objectInfo,
			btnOkElement,
		};
	},
};
</script>

<style scoped>
.xone-msgbox {
	position: absolute;
	background-color: rgba(0, 0, 0, 0.5);
	top: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100vh;
	pointer-events: all;
	animation: fadeIn 0.3s;
	z-index: 99999;
}

.xone-msgbox-box {
	max-width: 95vw;
	max-height: 95vh;
	padding: 20px;
	margin: 10px;
	display: flex;
	flex-direction: column;
	background-color: white;
	border-radius: 5px;
	border: 1px rgba(0, 0, 0, 0.14) solid;
	box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px rgba(0, 0, 0, 0.14), 0 1px 10px rgba(0, 0, 0, 0.12);
	animation: zoomIn 0.3s;
	overflow-x: hidden;
	overflow-y: auto;
}

.xone-msgbox-title {
	margin-bottom: 30px;
	width: 100%;
	font-size: 21px;
	text-align: justify;
}

.xone-msgbox-text {
	width: 100%;
	font-size: 18px;
	text-align: justify;
}

.xone-msgbox-options {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

.xone-msgbox-options button {
	margin-top: 30px;
	margin-left: 10px;
	padding: 5px 10px;
	border: none;
	color: #3273dc;
	border: 1px #3273dc solid;
	border-radius: 5px;
	background-color: transparent;
	font-size: 16px;
}

.xone-msgbox-options button:hover {
	cursor: pointer;
}

.xone-custom-msgbox {
	display: flex;
	flex-direction: column;
	animation: fadeIn 0.5s ease, zoomIn 0.3s ease;
}
</style>
