<template>
	<iframe v-if="isUrl" :src="propValue" :width="controlWidth" :height="controlHeight"></iframe>
	<iframe v-else :srcdoc="propValue" :width="controlWidth" :height="controlHeight"></iframe>
</template>

<script>
import { inject, PropType, ref, Ref, watchEffect } from "vue";
import { PropAttributes } from "../../composables/XoneAttributesHandler";
import { XoneDataObject } from "../../composables/appData/core/XoneDataObject";

export default {
	props: {
		/** xoneDataObject
     @type {PropType<XoneDataObject>} 
     */
		xoneDataObject: { type: Object, required: true },
		/** attributes
		 * @type { PropType<PropAttributes>}
		 */
		attributes: { type: Object, required: true },
		controlWidth: { type: Number, default: 0 },
		controlHeight: { type: Number, default: 0 },
	},
	setup(props) {
		const isUrl = ref(false);

		/**
		 * breadcrumbId
		 * @type {string}
		 */
		const breadcrumbId = inject("breadcrumbId");

		/**
		 * prop model value
		 * @type {Ref<any>}
		 */
		let propValue = ref();

		// Get appData model value
		watchEffect(() => {
			if (props.xoneDataObject?.model) propValue.value = props.xoneDataObject?.model[props.attributes.name];

			if (!propValue.value?.toString().contains("<") && !propValue.value?.toString().contains(">"))
				// URL
				return (isUrl.value = true);

			isUrl.value = false;
		});

		return {
			propValue,
			isUrl,
		};
	},
};
</script>

<style scoped>
iframe {
	box-sizing: border-box;
	border: none;
}
</style>
