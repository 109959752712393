<template>
	<Speedometer
		v-if="attributes.viewMode === 'speedometer'"
		:controlWidth="controlWidth"
		:controlHeight="controlHeight"
		:xoneDataObject="xoneDataObject"
		:attributes="attributes"
	/>
	<VerticalBar
		v-else-if="attributes.viewMode === 'verticalbar'"
		:controlWidth="controlWidth"
		:controlHeight="controlHeight"
		:xoneDataObject="xoneDataObject"
		:attributes="attributes"
	/>
	<template v-else>
		<!-- Input Checkbox -->
		<Checkbox
			v-if="attributes.type === 'NC'"
			:attributes="attributes"
			:xoneDataObject="xoneDataObject"
			:isDisabled="attributes.locked || isDisableEdit || isLinked || (objectInfo.isContents && !objectInfo.editInRow)"
			:controlWidth="controlWidth"
			:controlHeight="controlHeight"
			:checkboxSize="checkboxSize"
			@click="onClick($event)"
		></Checkbox>
		<!-- label -->
		<div
			v-if="attributes.labelWidth != 0 && !attributes.floatingTooltip"
			class="xone-input-label"
			:style="{
				// Size
				height: 'var(--label-height)',
				width: (attributes.type === 'TL' && '100%') || attributes.labelWidth,
				// Font
				fontSize: attributes.fontSize,
				// Background
				backgroundColor: attributes.bgColor,
				// Borders
				border: attributes.labelBox && `--border-width solid ${attributes.borderColor}`,
				// is CheckBox? -> Grow
				flexGrow: attributes.type === 'NC' && 1,
			}"
		>
			<label
				:style="{
					// Font
					color: attributes.foreColor,
					fontSize: attributes.fontSize,
					// Align
					alignItems: 'center',
					justifyContent: attributes.labelAlign,
					// Paddings
					paddingTop: 'var(--padding-top)',
					paddingRight: attributes.type === 'TL' ? 'var(--padding-right)' : '3px',
					paddingBottom: 'var(--padding-bottom)',
					paddingLeft: attributes.type === 'TL' ? 'var(--padding-left)' : '3px',
					cursor: objectInfo.isContents ? 'pointer' : null,
				}"
				class="xone-title"
				:class="{
					noEditInRow: objectInfo.isContents && !objectInfo.editInRow,
				}"
				>{{ attributes.title }}</label
			>
		</div>
		<!-- Input -->
		<div
			class="xone-input-container"
			v-if="attributes.type != 'TL' && attributes.type !== 'NC'"
			:style="{
				width: (attributes.width !== undefined && '100%') || attributes.fieldSize,
			}"
		>
			<!-- Show inline event click -->
			<div
				v-if="isLinked && !(attributes.type === 'AT' || attributes.locked || isDisableEdit || (objectInfo.isContents && !objectInfo.editInRow))"
				style="position: absolute; left: 0; top: 0; width: calc(100% - 30px); height: 100%; cursor: pointer"
				@click="onLinkedSearchButtonClick($event)"
			></div>

			<!-- 1 line -->
			<input
				v-if="!attributes.multiLine"
				ref="inputElement"
				:required="!(attributes.type.startsWith('D') || attributes.type === 'TT')"
				:disabled="attributes.type === 'AT' || attributes.locked || isDisableEdit || isLinked || (objectInfo.isContents && !objectInfo.editInRow)"
				@input="onInput"
				@change="onChange"
				:value="propValue"
				:type="inputType"
				:step="numberStep"
				:maxlength="(attributes.fixedText && attributes.size) || null"
				:style="{
					// Size
					width: (attributes.width !== undefined && '100%') || attributes.fieldSize,
					height: (controlHeight && `${controlHeight}px`) || attributes.lines,
					// Border
					borderRadius: attributes.borderCornerRadius,
					// Text
					textAlign: attributes.textAlign || attributes.align,
					textOverflow: attributes.textOverflow,
					fontSize: attributes.textFontSize ? attributes.textFontSize : attributes.fontSize,
					// Is Linked? -> padding-right in order to show the search button
					paddingRight: isLinked && '35px',
					cursor: objectInfo.isContents && (attributes.locked || isDisableEdit) ? 'pointer' : null,
				}"
				:class="{
					nobordertop: !attributes.borders.top,
					noborderright: !attributes.borders.right,
					noborderbottom: !attributes.borders.bottom,
					noborderleft: !attributes.borders.left,
					noEditInRow: objectInfo.isContents && !objectInfo.editInRow,
				}"
				@focus="onFocusChanged(true)"
				@blur="onFocusChanged(false)"
				@keydown="onKeyPress"
				@click="onClick($event)"
			/>
			<!-- 
        disabled: attributes.locked || isDisableEdit || isLinked, -->
			<!-- multi line -->
			<textarea
				ref="textAreaElement"
				v-else
				required
				:disabled="attributes.type === 'AT' || attributes.locked || isDisableEdit || isLinked || (objectInfo.isContents && !objectInfo.editInRow)"
				@input="onInput"
				:value="propValue"
				:maxlength="(attributes.fixedText && attributes.size) || null"
				:style="{
					// Size
					width: (attributes.width && '99%') || attributes.fieldSize,
					height: (controlHeight && `${controlHeight}px`) || attributes.lines,
					// Border
					borderRadius: attributes.borderCornerRadius,
					// Text
					textAlign: attributes.textAlign && attributes.textAlign,
					textOverflow: attributes.textOverflow,
					fontSize: attributes.textFontSize ? attributes.textFontSize : attributes.fontSize,
					// Is Linked? -> padding-right in order to show the search button
					paddingRight: isLinked && '35px',
					cursor: objectInfo.isContents && (attributes.locked || isDisableEdit) ? 'pointer' : null,
				}"
				:class="{
					nobordertop: !attributes.borders.top,
					noborderright: !attributes.borders.right,
					noborderbottom: !attributes.borders.bottom,
					noborderleft: !attributes.borders.left,
					disabled: attributes.locked || isDisableEdit,
					noEditInRow: objectInfo.isContents && !objectInfo.editInRow,
				}"
				@change="onChange()"
				@focus="onFocusChanged(true)"
				@blur="onFocusChanged(false)"
				@keydown="onKeyPress"
				@click="onClick($event)"
			/>
			<!-- Linked search button -->
			<button
				v-if="isLinked && !(objectInfo.isContents && !objectInfo.editInRow)"
				ref="linkedSearchElement"
				class="xone-input xone-input-linked"
				:style="{
					marginRight: attributes.undoButton ? '30px' : '10px',
					opacity: attributes.locked || isDisableEdit ? 0.5 : 1,
					cursor: attributes.locked || isDisableEdit ? 'default' : 'pointer',
					'--image-showinline': `url(${appPath}/assets/showinline.png)`,
					zIndex: 2,
				}"
				:disabled="attributes.locked || isDisableEdit"
				@click="onLinkedSearchButtonClick"
			></button>
			<button
				v-if="isLinked && !(objectInfo.isContents && !objectInfo.editInRow) && attributes.undoButton"
				ref="linkedSearchElement"
				class="xone-input xone-input-linked"
				:style="{
					opacity: attributes.locked || isDisableEdit ? 0.5 : 1,
					marginRight: '10px',
					cursor: attributes.locked || isDisableEdit ? 'default' : 'pointer',
					'background-image': `url(${appPath}/assets/close.png)`,
					zIndex: 1,
				}"
				:disabled="attributes.locked || isDisableEdit"
				@click="onUndoButtonClick"
			></button>
			<!-- Attach button -->
			<button
				v-if="attributes.type === 'AT' && !(objectInfo.isContents && !objectInfo.editInRow)"
				ref="linkedSearchElement"
				class="xone-input xone-input-attach"
				:style="{
					opacity: attributes.locked || isDisableEdit ? 0.5 : 1,
					cursor: attributes.locked || isDisableEdit ? 'default' : 'pointer',
					'--image-showinline': `url(${appPath}/assets/attach.png)`,
				}"
				:disabled="attributes.locked || isDisableEdit"
				@click="onAttachButtonClick"
			></button>
			<!-- Linked search component -->
			<LinkedSearchInline
				v-if="isLinkedSearchVisible"
				:xoneDataObject="xoneDataObject"
				:attributes="attributes"
				@onHide="isLinkedSearchVisible = false"
				@onSelectedItem="onSelectedItem"
				:linkedSearchPosition="linkedSearchPosition"
			></LinkedSearchInline>
			<!-- Tooltip -->
			<!-- v-if="!['D', 'DT', 'TT'].includes(attributes.type) && attributes.tooltip !== '' && !attributes.locked && !isDisableEdit" -->
			<label
				v-if="attributes.tooltip !== '' && ((!attributes.locked && !isDisableEdit) || attributes.floatingTooltip)"
				:class="[
					'xone-tooltip',
					attributes.floatingTooltip ? 'float' : 'nofloat',
					attributes.floatingTooltip && propValue ? 'linked-input-fill' : '',
				]"
				:for="attributes.name"
				:style="{
					color: attributes.tooltipColor,
					textAlign: attributes.textAlign,
					'--floating-tooltip-transform': floatingTooltipTransform,
					fontSize: attributes.tooltipFontSize,
				}"
				>{{ propValue && !attributes.floatingTooltip ? "" : attributes.tooltip }}</label
			>
		</div></template
	>
</template>

<script>
import { computed, inject, ref, Ref, ComputedRef, watch, PropType, onMounted, onUnmounted, nextTick } from "vue";

import { xoneAttributesHandler, PropAttributes } from "../../composables/XoneAttributesHandler";
import { XoneDataObject } from "../../composables/appData/core/XoneDataObject";
import xoneUI from "../../composables/XoneUI";
import LinkedSearchInline from "./LinkedSearchInline.vue";
import Checkbox from "./inputComponents/Checkbox.vue";
import Speedometer from "./inputComponents/Speedometer.vue";
import VerticalBar from "./inputComponents/VerticalBar.vue";
import { XoneControl, XoneView } from "../../composables/XoneViewsHandler";
import { getAppPath } from "../../composables/helperFunctions/ImageHelper";
import Button from "./Button.vue";
import { encrypt } from "../../composables/helperFunctions/StringHelper";

export default {
	components: { Checkbox, LinkedSearchInline, Button, Speedometer, VerticalBar },
	props: {
		/** xoneDataObject
		 * @type {PropType<XoneDataObject>}
		 */
		xoneDataObject: { type: Object, required: true },
		/** attributes
		 @type {PropType<PropAttributes>}
		*/
		attributes: { type: Object, required: true },
		isDisableEdit: { type: Boolean, required: true },
		checkboxSize: { type: Number, default: null },
		controlWidth: { type: Number, default: 0 },
		controlHeight: { type: Number, default: 0 },
		inputType: { type: String, default: "text" },
	},
	setup(props) {
		/**
		 * Get editInRow="false" if prop is in contents
		 * @type {import('../../composables/AppDataHandler').Objectinfo}
		 */
		const objectInfo = inject("objectInfo");

		const editInRow = objectInfo?.editInRow;

		/**
		 * Numeric step if property is float
		 * @type {ComputedRef<string>}
		 */
		const numberStep = computed(() => {
			if (props.attributes.type.length === 2 && props.attributes.type[0] === "N") {
				const n = parseInt(props.attributes.type[1]);
				return isNaN(n) ? "" : `.${Array(n).join("0")}1`;
			}
			return "";
		});

		/**
		 * Is linked property
		 * @type {ComputedRef<boolean>}
		 */
		const isLinked = computed(() => {
			return props.attributes.linkedTo && props.attributes.linkedTo !== "" && props.attributes.linkedField && props.attributes.linkedField !== "";
		});

		// show / hide linked search modal
		const isLinkedSearchVisible = ref(false);

		//
		// item selected from the linked search
		const onSelectedItem = ({ mapValue, value }) => {
			// Assign value to map prop
			props.xoneDataObject.changeModelValue(props.attributes.name, value);
			props.xoneDataObject.changeModelValue(props.attributes.linkedTo, mapValue);
			propValue.value = value;
		};

		/**
		 * prop model value
		 * @type {Ref<any>}
		 */
		const propValue = ref();

		onMounted(() => onChangeModelValue(props.xoneDataObject.model[props.attributes.name]));

		/**
		 * textAreaElement
		 * @type {Ref<HTMLElement|null>}
		 */
		const textAreaElement = ref();

		nextTick(() => fitGrowTextArea());

		//
		//Adjust textarea element height auto
		const fitGrowTextArea = async () => {
			if (!props.attributes.multiLine) return;
			if (!textAreaElement.value) return;
			if (props.attributes.height !== "grow" && props.attributes.height !== "auto") return;

			await nextTick();
			textAreaElement.value.style.height = "1px";
			textAreaElement.value.style.height = textAreaElement.value.scrollHeight + "px";

			await new Promise((resolve) => setTimeout(() => resolve(), 50));

			if (textAreaElement.value?.scrollHeight === 0 || textAreaElement.value?.clientHeight !== textAreaElement.value?.scrollHeight) fitGrowTextArea();
		};

		// Get appData model value
		watch(
			() => props.xoneDataObject.model[props.attributes.name],
			(newValue) => onChangeModelValue(newValue)
		);

		// On Model Value Changed
		const onChangeModelValue = (newValue) => {
			try {
				fitGrowTextArea();

				if (props.attributes.type.startsWith("D")) {
					/**
					 * value
					 * @type {Date}
					 */
					const value = newValue;
					if (!value) return;
					propValue.value = value.toXoneDate(props.attributes.type);
				}
				// Codificado o encriptado
				else if (props.attributes.type === "X") {
					try {
						if (!newValue) {
							propValue.value = newValue;
							decodedText.value = newValue;
						} else if (decodedText.value) propValue.value = decodedText.value;
						else if (props.attributes.hashType) propValue.value = newValue;
						else propValue.value = window.atob(newValue);
					} catch {
						propValue.value = newValue;
					}
				} else if (propValue.value !== newValue) propValue.value = newValue;
			} catch (ex) {
				console.error(ex);
			}
		};

		// Last key pressed
		let keyPressed = "";
		let isExecutingAction = false;

		const onKeyPress = ({ key }) => {
			if (key !== "Enter") return;
			// set Last key
			keyPressed = key;
			// Execute oneditaction
			if (props.attributes.onEditorAction && !isExecutingAction) {
				isExecutingAction = true;
				xoneAttributesHandler
					.executeMethod(props.attributes.onEditorAction, props.xoneDataObject)
					.catch(console.error)
					.finally(() => (isExecutingAction = false));
			}
			// Execute binded event in script
			xoneAttributesHandler.executeBindedEvent(props.xoneDataObject, props.attributes, "oneditoraction", {});
		};

		const decodedText = ref("");
		let changeDateTimeout;
		// Update appData model value
		const onInput = ({ target }) => {
			const oldValue = propValue.value;
			const newValue = target.value;

			if (changeDateTimeout) clearTimeout(changeDateTimeout);
			// Aplicamos un debounce para dar tiempo al usuario a setear por completo la fecha
			changeDateTimeout = setTimeout(
				async () => {
					// In mobile keypress does not work
					if (!keyPressed || keyPressed === "Unidentified") {
						if (newValue.includes(oldValue)) keyPressed = newValue.replace(oldValue, "");
					}

					// propValue.value = target.value;
					if (props.xoneDataObject[props.attributes.name] === newValue) return;

					try {
						// Update data model
						if (props.attributes.type.startsWith("D")) {
							props.xoneDataObject.changeModelValue(props.attributes.name, isNaN(new Date(newValue)) ? null : new Date(newValue));
						}
						// Es un campo codificado o encriptado
						else if (props.attributes.type === "X") {
							// Si nos especifican encriptación almacenamos la cadena y enviamos el encriptado
							if (props.attributes.hashType) {
								decodedText.value = target.value;
								propValue.value = target.value;
								props.xoneDataObject.changeModelValue(
									props.attributes.name,
									decodedText.value ? await encrypt(decodedText.value, props.attributes.hashType) : ""
								);
							}
							// Encode a base64
							else props.xoneDataObject.changeModelValue(props.attributes.name, btoa(newValue));
						} else props.xoneDataObject.changeModelValue(props.attributes.name, newValue);

						try {
							if (props.attributes.onTextChanged)
								await props.xoneDataObject.DoRunScriptAsync(
									`let e = { target: '${props.attributes.name}', objItem: '${props.attributes.name}', newText: '${newValue}', oldText: '${oldValue}', keyPressed: '${keyPressed}' };
                  			${props.attributes.onTextChanged}`
								);
						} catch (ex) {
							xoneUI.showSnackbar({
								text: ex,
								color: "red",
							});
						}
						// Execute binded event in script
						await xoneAttributesHandler.executeBindedEvent(props.xoneDataObject, props.attributes, "ontextchanged", {
							newText: newValue,
							oldText: oldValue,
							keyPressed,
						});
					} catch (ex) {
						console.error(ex);
					}
				},
				props.attributes.type.startsWith("D") || props.attributes.type === "TT" ? 1500 : 0
			);
		};

		/**
		 * on value change
		 */
		const onChange = async () => {
			try {
				if (editInRow && objectInfo.autosave) {
					await props.xoneDataObject.save();
				}
			} catch (ex) {
				xoneUI.showSnackbar({
					text: ex,
					color: "red",
				});
			}
		};

		/**
		 * xoneView
		 * @type {XoneView}
		 */
		const xoneView = inject("xoneView");
		onMounted(() => {
			// Add control to view
			xoneView.addControl(new XoneControl(props.attributes.name, inputElement.value || textAreaElement.value));
		});

		// Check if an input is focused in order to handle keyboard in mobile devices
		const isInputFocused = inject("isInputFocused");

		onUnmounted(() => (isInputFocused.value = false));

		/**
		 * on focus changed
		 */
		const onFocusChanged = async (isFocused) => {
			try {
				isInputFocused.value = isFocused;
				if (props.attributes.onFocusChanged)
					await props.xoneDataObject.DoRunScriptAsync(
						`let e = { target: '${props.attributes.name}',objItem: '${props.attributes.name}',isFocused: ${isFocused} };
            ${props.attributes.onFocusChanged}`
					);
			} catch (ex) {
				xoneUI.showSnackbar({
					text: ex,
					color: "red",
				});
			}
			// Execute binded event in script
			await xoneAttributesHandler.executeBindedEvent(props.xoneDataObject, props.attributes, "onfocuschanged", { isFocused });
		};

		/**
		 * Window Size
		 * @type {{containerHeight: Ref<number>|ComputedRef<number>, containerWidth: Ref<number>|ComputedRef<number>}}
		 */
		const { containerHeight, containerWidth } = inject("containerSize");
		watch(
			() => containerHeight.value,
			() => fitGrowTextArea()
		);
		watch(
			() => containerWidth.value,
			() => fitGrowTextArea()
		);

		/**
		 * floating tooltip on focus transform
		 * @type {ComputedRef<string|null>}
		 */
		const floatingTooltipTransform = computed(() => {
			if (!props.attributes.floatingTooltip) return null; // Not floating prop
			if (props.controlHeight && props.controlHeight !== 0)
				// Is floating and returns control height
				return `translate3d(0, calc(${
					// Is floating and returns default value
					(-1 * props.controlHeight) / 2
				}px + .4rem), 0)`;
			return "translate3d(0, -1.5rem, 0)";
			// 	return `translate3d(-.5rem, calc(${
			// 		// Is floating and returns default value
			// 		(-1 * props.controlHeight) / 2
			// 	}px + .4rem), 0)`;
			// return "translate3d(-.5rem, -1.5rem, 0)";
		});

		/**
		 * linkedSearchElement
		 * @type {Ref<HTMLElement>}
		 */
		const linkedSearchElement = ref();

		/**
		 * inputElement
		 * @type {Ref<HTMLElement>}
		 */
		const inputElement = ref();

		const linkedSearchPosition = ref();

		const onLinkedSearchButtonClick = (/** @type {Event} */ e) => {
			calculateLinkedSearchButtonPosition();
			isLinkedSearchVisible.value = true;
			e.stopPropagation();
		};

		const onAttachButtonClick = (/** @type {Event} */) => {
			xoneUI.pickFile(props.attributes.name, props.attributes.allowedExtensions);
			e.stopPropagation();
		};

		const calculateLinkedSearchButtonPosition = () => {
			if (!linkedSearchElement.value) return;

			const element = inputElement.value || textAreaElement.value;

			linkedSearchPosition.value = {
				top: linkedSearchElement.value.getBoundingClientRect().top,
				left: linkedSearchElement.value.getBoundingClientRect().left - (window.innerWidth - containerWidth.value) / 2,
				width: element.offsetWidth - linkedSearchElement.value.offsetWidth - 40,
				height: element.offsetHeight,
			};
		};

		const onLabelClick = () => {
			if (!props.attributes.type === "NC") return;
			if (propValue.value === 0) propValue.value = 1;
			else propValue.value = 0;
			props.xoneDataObject.changeModelValue(props.attributes.name, propValue.value);
		};

		return {
			numberStep,
			isLinked,
			isLinkedSearchVisible,
			onSelectedItem,
			propValue,
			objectInfo,
			textAreaElement,
			onInput,
			onChange,
			onFocusChanged,
			onKeyPress,
			floatingTooltipTransform,
			onLinkedSearchButtonClick,
			onAttachButtonClick,
			linkedSearchElement,
			inputElement,
			linkedSearchPosition,
			appPath: getAppPath(),
			onLabelClick,
			onUndoButtonClick: () => {
				props.xoneDataObject.changeModelValue(props.attributes.linkedTo, null);
			},
			onClick: (/** @type {Event} */ e) => {
				e.stopPropagation();
			},
		};
	},
};
</script>

<style scoped>
* {
	font-weight: var(--text-weight);
}

/* label container */
.xone-input-label {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;

	/* border-radius: 3px; */
}

/* prepend label */
.xone-title {
	display: inline-block;
	width: 100%;
	flex-grow: 1;
	vertical-align: middle;
	height: auto;
	display: flex;
	align-items: flex-start;
}

/* input */
.xone-input-container {
	display: flex;
	position: relative;
	align-items: center;
	flex-grow: 1;
	flex-shrink: 2;
}

input,
textarea {
	flex-grow: 1;
	flex-shrink: 2;
	border: var(--border-width) solid var(--border-color);
	width: 12rem;
	resize: none;
	background-color: var(--input-bgcolor);
	color: var(--input-forecolor);
	padding-top: var(--padding-top);
	padding-right: var(--padding-right);
	padding-bottom: var(--padding-bottom);
	padding-left: var(--padding-left);
}

input:focus,
textarea:focus {
	outline: none;
}

.xone-input {
	position: absolute;
	right: 0;
	background-color: transparent;
	border: none;
	background-image: var(--image-showinline);
	background-repeat: no-repeat;
	background-position: center;
	outline: none;
	width: 25px;
	height: 25px;
	padding: 5px 5px;
}

.xone-input-linked {
	background-size: 16px 16px;
}

.xone-input-attach {
	background-size: 20px 20px;
}

.xone-input-linked:hover {
	cursor: pointer;
	border-radius: 3px;
}

.noEditInRow {
	cursor: pointer;
	pointer-events: none;
}

.nobordertop {
	border-top: 0;
}

.noborderright {
	border-right: 0;
}

.noborderbottom {
	border-bottom: 0;
}

.noborderleft {
	border-left: 0;
}

/* tooltip */
.xone-tooltip {
	position: absolute;
	padding: 7px 0 0 13px;
	transition: all 200ms;
	opacity: 0.5;
	flex-grow: 0;
	font-size: 0.8rem;
	height: auto;
	pointer-events: none;

	padding-top: var(--padding-top);
	padding-right: var(--padding-right);
	padding-bottom: var(--padding-bottom);
	padding-left: var(--padding-left);
}

.float {
	top: auto;
}

.nofloat {
	top: auto;
	width: 100%;
}

input:focus + .float,
input:valid + .float,
.linked-input-fill {
	font-size: 0.8rem;
	transform: var(--floating-tooltip-transform);
	opacity: 1;
}

input:focus + .nofloat,
input:valid + .nofloat {
	opacity: 0;
}

input[type="number"]::-webkit-inner-spin-button:disabled,
input[type="date"]::-webkit-inner-spin-button:disabled,
input[type="time"]::-webkit-inner-spin-button:disabled,
input[type="datetime-local"]::-webkit-inner-spin-button:disabled {
	-webkit-appearance: none;
}

input[type="number"]:disabled,
input[type="date"]:disabled,
input[type="time"]:disabled,
input[type="datetime-local"]:disabled {
	-moz-appearance: textfield;
}

::-webkit-calendar-picker-indicator {
	margin-left: -15px;
}
</style>
