import { createRouter, createWebHashHistory } from "vue-router"; //createWebHistory//createWebHashHistory

const history = createWebHashHistory(process.env.BASE_URL);

const routes = [
	{
		path: "/",
		name: "Home",
		component: () => import(/* webpackChunkName: "about" */ "../views/Home.vue"),
	},

	{
		path: "/o",
		name: "Coll",
		component: () => import(/* webpackChunkName: "about" */ "../views/Home.vue"),
	},

	{
		path: "/login/:id?",
		name: "Login",
		component: () => import(/* webpackChunkName: "about" */ "../views/Home.vue"),
	},

	{
		path: "/deeplink/",
		name: "DeepLink",
		component: () => import(/* webpackChunkName: "coll" */ "../views/Home.vue"),
	},

	{
		path: "/preview/",
		name: "Preview",
		component: () => import(/* webpackChunkName: "coll" */ "../views/Home.vue"),
	},

	{
		path: "/404/",
		name: "404",
		component: () => import(/* webpackChunkName: "coll" */ "../views/404.vue"),
	},

	{
		path: "/logon/",
		name: "Logon",
		component: () => import(/* webpackChunkName: "coll" */ "../views/Login.vue"),
	},
];

const router = createRouter({
	history,
	routes,
});

export default router;
