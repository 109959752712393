<template>
  <video :width="controlWidth" :height="controlHeight" controls>
    <source :src="`/files/${propValue}`" />
  </video>
</template>

<script>
import { PropType, ref, Ref, watchEffect } from "vue";
import { PropAttributes } from "../../composables/XoneAttributesHandler";
import { XoneDataObject } from "../../composables/appData/core/XoneDataObject";

export default {
  props: {
    /** xoneDataObject
     @type {PropType<XoneDataObject>} 
     */
    xoneDataObject: { type: Object, required: true },
    /** attributes
     * @type { PropType<PropAttributes>}
     */
    attributes: { type: Object, required: true },
    controlWidth: { type: Number, default: 0 },
    controlHeight: { type: Number, default: 0 },
  },
  setup(props) {
    /**
     * prop model value
     * @type {Ref<any>}
     */
    let propValue = ref();

    // Get appData model value
    watchEffect(() => {
      if (props.xoneDataObject?.model) propValue.value = props.xoneDataObject?.model[props.attributes.name];
    });

    return { propValue };
  },
};
</script>

<style scoped>
video {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  transition: all 0.1s;
}
</style>
