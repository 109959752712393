import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import Container from "./components/Container.vue";
import "./composables/helperFunctions/DateHelper";
import "./composables/helperFunctions/DeviceHelper";
import "./composables/ScriptGlobalObjects";
import dragAndDrop from "./composables/DragAndDrop";

// Cargamos el fichero de configuración

// Get App Configuration File and Script_Wrapper
const getConfig = Function(
	"cbF",
	"(async()=>{try{const c=await import('../configuration.js');cbF(c.default);}catch(ex){console.error('Error loading configuration.js',ex);}})()"
);

getConfig((config) => {
	const mode = config.mode || "SPA";

	// Iniciamos la App
	if (mode === "DESIGNER") dragAndDrop.setIsDesigner(true);
	createApp(App).use(router).component("Container", Container).mount("xone-app");

	// PWA
	if (mode === "PWA")
		if (navigator.serviceWorker)
			// SW
			navigator.serviceWorker.register("./sw.js");

	// Disable navigator forward button
	window.addEventListener(
		"popstate",
		(event) => {
			if (!event.state) return;
			if (typeof event.state == "object" && event.state.obsolete !== true) {
				history.replaceState({ obsolete: true }, "");
				history.pushState(event.state, "");
			}

			if (typeof event.state == "object" && event.state.obsolete === true) {
				history.back();
			}
		},
		false
	);

	// Lock scale
	const viewportmeta = document.querySelector('meta[name="viewport"]');
	if (viewportmeta) {
		viewportmeta.content = "width=device-width, minimum-scale=1.0, maximum-scale=1.0, initial-scale=1.0";
		document.body.addEventListener(
			"gesturestart",
			function () {
				viewportmeta.content = "width=device-width, minimum-scale=0.25, maximum-scale=1.6";
			},
			false
		);
	}
});

// Creamos un nodo xone-app para contener la aplicación
class XoneApp extends HTMLElement {
	constructor() {
		super();
	}

	connectedCallback() {
		const divElement = document.createElement("div");
		divElement.id = "app";
		this.appendChild(divElement);
	}
}
window.customElements.define("xone-app", XoneApp);
