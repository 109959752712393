import firebase from "firebase/app";
import "firebase/messaging";
import AppDataHandler from "./AppDataHandler";
import { generateUniqueId } from "./helperFunctions/StringHelper";
import { XoneDataCollection } from "./appData/core/XoneDataCollection";

import xoneUI from "./XoneUI";

/** @type {string} */
let pushToken;

/**
 * get firebase configuration
 * @returns {Promise<{firebaseConfig: string, vapidKey: string}>}
 */
const getFirebaseConfig = async () =>
	await new Promise((resolve) => {
		const /** @type {Worker} */ worker = Function("return new Worker('../tasks.js')")();
		worker.addEventListener("message", ({ data }) => resolve(data));
		worker.postMessage({ type: "firebaseConfig" });
	});

/**
 * Init firebase
 */
const initFirebase = async () => {
	try {
		const { firebaseConfig, vapidKey } = await getFirebaseConfig();

		firebase.initializeApp(firebaseConfig);

		const messaging = firebase.messaging();

		const firebaseToken = await messaging.getToken({ vapidKey });

		if (firebaseToken) {
			localStorage.setItem("_messagingToken", firebaseToken);
			pushToken = firebaseToken;
			onPushRegisteredFunctions.forEach((onPushRegisteredFunction) => onPushRegisteredFunction({ pushToken }));
		} else {
			const ex = "No registration token available. Request permission to generate one.";
			console.error(ex);
			throw new Exception(ex);
		}

		messaging.onMessage((payload) => {
			const id = payload.data?.notification_id || generateUniqueId();
			const title = payload.data?.title || payload.notification?.notification_title;
			const body = payload.data?.body || payload.notification?.notification_message;
			const onClickFunction = () =>
				AppDataHandler.getAppData()
					.getCollection("Empresas")
					.then((/** @type {XoneDataCollection} */ coll) => coll.ExecuteCollAction("onpushnotificationclick", null, payload).catch(console.error));

			xoneUI.showNotification(id, title, body, null, onClickFunction);

			onPushReceivedFunctions.forEach((onPushReceivedFunction) => onPushReceivedFunction(payload));
		});
	} catch (ex) {
		console.error("An error occurred while retrieving token. ", ex);
		throw new Exception(ex);
	}
};

/**
 * onPushRegisteredFunction
 * @type {Map<string,Function>}
 */
const onPushRegisteredFunctions = new Map();

/**
 * onPushRegistrationFailureFunction
 * @type {Map<string,Function>}
 */
const onPushRegistrationFailureFunctions = new Map();

/**
 * onPushReceivedFunction
 * @type {Map<string,Function>}
 */
const onPushReceivedFunctions = new Map();

/**
 * register push
 * @param {Function} [callback]
 */
export const registerPush = (callback) =>
	!pushToken &&
	Notification.requestPermission().then(() =>
		initFirebase()
			.then(() => pushToken && callback instanceof Function && callback({ pushToken }))
			.catch(() => onPushRegistrationFailureFunctions.forEach((onPushRegistrationFailureFunction) => onPushRegistrationFailureFunction()))
	);

/**
 * OnPushRegistered binded event function
 * @param {Function} callback
 */
export const setOnPushRegisteredFunction = (callback) => {
	if (onPushRegisteredFunctions.has(callback.toString())) return;
	onPushRegisteredFunctions.set(callback.toString(), callback);
	if (pushToken) onPushRegisteredFunctions.forEach((onPushRegisteredFunction) => onPushRegisteredFunction({ pushToken }));
};

/**
 * onPushRegistrationFailure binded event function
 * @param {Function} callback
 */
export const setOnPushRegistrationFailureFunction = (callback) =>
	!onPushRegistrationFailureFunctions.has(callback.toString()) && onPushRegistrationFailureFunctions.set(callback.toString(), callback);

/**
 * onPushReceived binded event function
 * @param {Function} callback
 */
export const setOnPushReceivedFunction = (callback) =>
	!onPushReceivedFunctions.has(callback.toString()) && onPushReceivedFunctions.set(callback.toString(), callback);
