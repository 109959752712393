<template>
	<div class="tab" ref="groupsElement" :style="{}">
		<template v-for="(group, index) in groups" :key="`grp-btn-${group.attributes.id}`">
			<button
				:class="['tablinks', activeGroup === group.attributes.id && 'activeGroup']"
				@click="changeGroup(group.attributes.id)"
				v-if="groupsVisibility[index]"
				:style="{
					// BackgroundColor
					backgroundColor: attributes.tabBgColor,
					// Variables
					'--text-color': attributes.tabForecolor,
					'--selected-text-color': attributes.tabSelectedForecolor,
					'--selected-bgcolor': attributes.tabSelectedBgcolor,
					'--selected-fontsize': attributes.tabSelectedFontsize,
					// Padding
					paddingTop: attributes.tabPaddings.top,
					paddingRight: attributes.tabPaddings.right,
					paddingBottom: attributes.tabPaddings.bottom,
					paddingLeft: attributes.tabPaddings.left,
					// FontSize
					fontSize: attributes.tabFontSize,
					// Radius
					borderRadius: attributes.tabBorderCornerRadius,
				}"
			>
				{{ group.attributes.name }}
			</button>
		</template>
	</div>
</template>

<script>
import { inject, nextTick, ref, watch, Ref, onMounted } from "vue";
import XmlNode from "../composables/appData/Xml/JSONImpl/XmlNode";
import { xoneAttributesHandler } from "../composables/XoneAttributesHandler";

export default {
	name: "Group",

	props: {
		attributes: { type: Object, required: true },
		groups: {
			type: Array,
			default: new Array(),
		},
		containerHeight: { type: Number, default: 0 },
		/**
		 * xoneDataObject
		 * @type {PropType<XoneDataObject>}
		 */
		xoneDataObject: { type: Object, required: true },
	},

	emits: ["onHeightChanged"],

	setup(props, context) {
		/** @type {{activeGroup: Ref<string>, changeGroup: Function}} */
		const { activeGroup, changeGroup } = inject("groupHandler");

		/** @type {Ref<HTMLElement>} */
		const groupsElement = ref();

		nextTick(() => context.emit("onHeightChanged", groupsElement.value?.clientHeight || 0));

		watch(
			() => props.containerHeight,
			async () => {
				await nextTick();
				context.emit("onHeightChanged", groupsElement.value?.clientHeight || 0);
			}
		);

		/** @type {Ref<Function[]>} */
		const functionsToExecuteOnDatamodelChange = inject("functionsToExecuteOnDatamodelChange");

		const groupsVisibility = ref(new Array(props.groups.length));

		onMounted(() => {
			/** @type {XmlNode} */
			const m_xmlNode = props.xoneDataObject.getOwnerCollection().m_xmlNode;
			m_xmlNode.SelectNodes("group").forEach((/** @type {XmlNode} */ e, /** @type {number} */ index) => {
				const formula = e.getAttrValue("disablevisible");
				if (!formula) return (groupsVisibility.value[index] = true);
				const fnEvalFormula = () => (groupsVisibility.value[index] = !xoneAttributesHandler.evalFormula(formula, props.xoneDataObject?.model));
				// Agregamos la función de evaluación de fórmula de disablevisible para ejecutarse cuando cambie el data model
				functionsToExecuteOnDatamodelChange.value.push(fnEvalFormula);
				fnEvalFormula();
			});
		});

		return { activeGroup, changeGroup, groupsElement, groupsVisibility };
	},
};
</script>

<style scoped>
/* Style the tab */
.tab {
	overflow: hidden;
	/* border: 1px solid #ccc; */
	border-top: none;
	border-right: none;
	border-left: none;
	display: flex;
	overflow-x: auto;
	flex-shrink: 0;
}

/* Style the buttons inside the tab */
.tab button {
	background-color: inherit;
	color: var(--text-color);
	border: none;
	outline: none;
	cursor: pointer;
	padding: 7px 8px;
	transition: 0.2s;
	font-size: 17px;
	flex-shrink: 0;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
}

/* Change background color of buttons on hover */
.tab button:hover {
	color: black;
	/* border-bottom: 1px solid black; */
}

/* Create an activeGroup/current tablink class */
.tab button.activeGroup {
	color: var(--selected-text-color) !important;
	background-color: var(--selected-bgcolor) !important;
	font-size: var(--selected-fontsize) !important;
	/* border-bottom: 1px solid var(--selected-text-color); */
}
</style>
