import { ref, Ref } from "@vue/reactivity";

class DragAndDrop {
  /**
   * @type {DragAndDrop}
   */
  static _instance;

  /**
   * @type {Ref<boolean>}
   */
  _isDesigner = ref(false);

  /**
   * @type {Ref<boolean>}
   */
  _isDragging = ref(false);

  /**
   * @type {Ref<HTMLDivElement>}
   */
  _selectedControl = ref();

  /**
   * @type {Ref<HTMLDivElement>}
   */
  _dragControl = ref();

  /**
   * @type {Ref<HTMLDivElement>}
   */
  _overControl = ref();

  /**
   * @type {Ref<Array<HTMLDivElement>>}
   */
  _overControls = ref([]);

  /**
   * @type {number}
   */
  _designPadding = 10;

  constructor() {
    if (DragAndDrop._instance) return DragAndDrop._instance;
    DragAndDrop._instance = this;
  }

  /**
   * @param {MouseEvent} e
   */
  mouseMove(e) {
    if (!this._isDesigner && !this._isDragging) return (this._dragControl.value = null);

    /**
     * @type {HTMLElement}
     */
    let element = e.target;
    while (element && !element.getAttribute("xone-name")) element = element.parentElement;
    this._dragControl.value = element;
  }

  /**
   * @type {boolean}
   */
  setIsDesigner(value) {
    this._isDesigner.value = value;
  }

  /**
   * @type {boolean}
   */
  setIsDragging(value) {
    this._isDragging.value = value;
    this._dragControl.value = null;
  }

  /**
   * @returns {Ref<boolean>}
   */
  getIsDesigner() {
    return this._isDesigner;
  }

  /**
   * @returns {Ref<boolean>}
   */
  getIsDragging() {
    return this._isDragging;
  }

  /**
   * @returns {number}
   */
  getDesignPadding() {
    return this._designPadding;
  }

  /**
   * @type {HTMLDivElement}
   */
  setSelectedControl(value) {
    this._selectedControl.value = value;
  }

  /**
   * @type {HTMLDivElement}
   */
  setOverControl(value) {
    if (value) return (this._overControl.value = value);
    this._overControl.value = this._overControls.value.length !== 0 ? this._overControls.value[this._overControls.value.length - 1] : null;
  }

  /**
   * @type {HTMLDivElement}
   */
  addOverControl(value) {
    if (this._overControls.value.indexOf(value) !== -1) return;
    this.pushOverControl(value);
  }

  /**
   * @type {HTMLDivElement}
   */
  pushOverControl(value) {
    this._overControls.value.push(value);
    this.setOverControl();
  }

  /**
   * @type {HTMLDivElement}
   */
  removeOverControl(value) {
    this._overControls.value = this._overControls.value.filter((e) => e !== value);
    this.setOverControl();
  }

  /**
   * @returns {Ref<HTMLDivElement>}
   */
  getSelectedControl() {
    return this._selectedControl;
  }

  /**
   * @returns {Ref<HTMLDivElement>}
   */
  getDragControl() {
    return this._dragControl;
  }

  /**
   * @returns {Ref<HTMLDivElement>}
   */
  getOverControl() {
    return this._overControl;
  }

  /**
   * @returns {Array<HTMLDivElement>}
   */
  getOverControls() {
    return this._overControls.value;
  }

  /**
   * @returns {string}
   */
  getBorderPropOver() {
    return "3px dashed #1F3C6E";
  }

  /**
   * @returns {string}
   */
  getBorderContainerOver() {
    return "3px solid #4CABD5";
  }

  /**
   * @returns {string}
   */
  getBorderSelected() {
    return "3px dashed #1F3C6E";
  }

  /**
   * @returns {string}
   */
  getBorderOver() {
    return "3px dashed #4CABD5";
  }

  /**
   * @param {HTMLElement} element
   */
  mouseEnter(element) {
    this.addOverControl(element);
  }

  /**
   * @param {HTMLElement} element
   */
  mouseLeave(element) {
    this.removeOverControl(element);
  }

  /**
   * @param{MouseEvent} e
   */
  mouseDown(e) {
    if (!this._isDesigner) return;
    e.preventDefault();
    e.stopPropagation();
    this.setSelectedControl(this._overControls ? this._overControls.value[this._overControls.value.length - 1] : null);
  }
}

export default new DragAndDrop();
