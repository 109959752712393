import { Ref, nextTick, watch } from "vue";
import { XoneDataObject } from "./appData/core/XoneDataObject";
import { getView } from "./XoneViewsHandler";

/** @type {boolean} */
let _isScaleFontsize = false;

/** @type {string} */
let _webFontFactor = "6";

/** @type {number} */
let _resolutionWidth = -1;

/** @type {number} */
let _resolutionHeight = -1;

/**
 * setIsScaleFontsize
 * @param {boolean} value
 */
export const setIsScaleFontsize = (value) => (_isScaleFontsize = value);

/**
 * setResolutionWidth
 * @param {number} value
 */
export const setResolutionWidth = (value) => (_resolutionWidth = value);

/**
 * setResolutionHeight
 * @param {number} value
 */
export const setResolutionHeight = (value) => (_resolutionHeight = value);

/**
 * setWebFontFactor
 * @param {number} value
 */
export const setWebFontFactor = (value) => (_webFontFactor = value);

/**
 * Types Definitions
 *
 * @typedef {Object} Margins
 * @property {string} top
 * @property {string} right
 * @property {string} bottom
 * @property {string} left
 *
 * @typedef {Object} Paddings
 * @property {string} top
 * @property {string} right
 * @property {string} bottom
 * @property {string} left
 *
 * @typedef {Object} Borders
 * @property {boolean} top
 * @property {boolean} right
 * @property {boolean} bottom
 * @property {boolean} left
 *
 * @typedef {Object} ContainerAlign
 * @property {string} row
 * @property {string} column
 *
 * @typedef {Object} Floating
 * @property {boolean} floating
 * @property {string} [top]
 * @property {string} [left]
 *
 * @typedef {Object} ContainerAttributes
 * @property {Object} align
 * @property {string} animationIn
 * @property {string} animationOut
 * @property {string} bgColor
 * @property {string} bMargin
 * @property {string} borderColor
 * @property {string} borderCornerRadius
 * @property {Borders} borders
 * @property {number} borderWidth
 * @property {string} cellEvenColor
 * @property {string} cellOddColor
 * @property {string} cellSelectedBgColor
 * @property {string} disableVisible
 * @property {string} drawerOrientation
 * @property {number} elevation
 * @property {string} fixed
 * @property {Floating} floating
 * @property {string} foreColor
 * @property {boolean} framebox
 * @property {boolean} groupSwipe
 * @property {string} height
 * @property {string} href
 * @property {string} id
 * @property {string} image
 * @property {boolean} keepAspectRatio
 * @property {string} lMargin
 * @property {boolean} loadAll
 * @property {Margins} margins
 * @property {string} minHeight
 * @property {string} minWidth
 * @property {string} name
 * @property {boolean} newLine
 * @property {string} node
 * @property {boolean} noTab
 * @property {string} onClick
 * @property {string} onLongPress
 * @property {string} onFocus
 * @property {string} onScroll
 * @property {string} orientation
 * @property {Paddings} paddings
 * @property {string} rMargin
 * @property {boolean} scroll
 * @property {string} scrollOrientation
 * @property {string} tabBorderCornerRadius
 * @property {string} tabBgColor
 * @property {string} tabFontSize
 * @property {string} tabSelectedFontsize
 * @property {string} tabForecolor
 * @property {Paddings} tabPaddings
 * @property {string} tabSelectedBgcolor
 * @property {string} tabSelectedForecolor
 * @property {string} tMargin
 * @property {string} viewMode
 * @property {string} width
 * @property {boolean} wrap
 *
 * @typedef {Object} PropAttributes
 * @property {ContainerAlign|string} align
 * @property {string} allowedExtensions
 * @property {boolean} autosave
 * @property {Number} autoslideDelay
 * @property {string} barColor
 * @property {string} bgColor
 * @property {string} bit
 * @property {string} bMargin
 * @property {string} borderColor
 * @property {string} borderCornerRadius
 * @property {Borders} borders
 * @property {number} borderWidth
 * @property {number} breadcrumbFontSize
 * @property {number} breadcrumbForeColor
 * @property {number} breadcrumbSelectedFontSize
 * @property {number} breadcrumbSelectedForeColor
 * @property {string} buttonOption
 * @property {string} calendarViewMode
 * @property {string} cellBgColor
 * @property {string} cellBorderColor
 * @property {string} cellEvenColor
 * @property {string} cellForeColor
 * @property {string} cellOddColor
 * @property {string} cellOtherMonthBgColor
 * @property {string} cellSelectedBgColor
 * @property {string} cellSelectedBorderColor
 * @property {string} cellSelectedForeColor
 * @property {boolean} circleRadius
 * @property {boolean} clusterMarkers
 * @property {Object} contents
 * @property {string} disableEdit
 * @property {string} disableVisible
 * @property {boolean} editInRow
 * @property {number} elevation
 * @property {string} fieldSize
 * @property {boolean} fixedText
 * @property {Floating} floating
 * @property {boolean} floatingTooltip
 * @property {boolean} fluidLoad
 * @property {boolean} fontBold
 * @property {string} fontSize
 * @property {string} foreColor
 * @property {string} foreColorDisabled
 * @property {number} galleryColumns
 * @property {string} hashType
 * @property {string} height
 * @property {string} href
 * @property {string} image
 * @property {string} imgChecked
 * @property {string} imgCheckedDisabled
 * @property {string} imgHeight
 * @property {string} imgUnchecked
 * @property {string} imgUncheckedDisabled
 * @property {string} imgWidth
 * @property {number} interval
 * @property {boolean} isRadio
 * @property {boolean|Number} isVisible
 * @property {boolean} keepAspectRatio
 * @property {string} labelAlign
 * @property {boolean} labelBox
 * @property {string} labelWidth
 * @property {string} lines
 * @property {string} linkedField
 * @property {string} linkedTo
 * @property {string} lMargin
 * @property {boolean} locked
 * @property {boolean} lockedOnScript
 * @property {Object} margins
 * @property {Object} method
 * @property {string} minHeight
 * @property {number} minValue
 * @property {number} maxValue
 * @property {number} startAngle
 * @property {number} endAngle
 * @property {number} offset
 * @property {string} minWidth
 * @property {boolean} multiLine
 * @property {string} name
 * @property {string} node
 * @property {string} objectFit
 * @property {string} onClick
 * @property {string} onLongPress
 * @property {string} onEditorAction
 * @property {Object} onFocusChanged
 * @property {string} onScroll
 * @property {Object} onTextChanged
 * @property {Paddings} paddings
 * @property {number} paginationSize
 * @property {string} postOnchange
 * @property {string} radioGroup
 * @property {boolean} readOnly
 * @property {string} rMargin
 * @property {number} rowsPerPage
 * @property {boolean} showInline
 * @property {boolean} showInlineKeyboard
 * @property {boolean} showInMarker
 * @property {boolean} showPois
 * @property {number} size
 * @property {boolean} startFromBottom
 * @property {string} textAlign
 * @property {string} textBgColor
 * @property {string} textBgColorDisabled
 * @property {Object} textBorder
 * @property {boolean} textBorderBottom
 * @property {boolean} textBorderLeft
 * @property {boolean} textBorderRight
 * @property {boolean} textBorderTop
 * @property {string} textOverflow
 * @property {string} textFontSize
 * @property {string} textForeColor
 * @property {string} textForeColorDisabled
 * @property {string} title
 * @property {string} tMargin
 * @property {string} tooltip
 * @property {string} tooltipColor
 * @property {string} tooltipForeColor
 * @property {string} type
 * @property {boolean} undoButton
 * @property {string} viewMode
 * @property {boolean} weekdaysLongname
 * @property {string} width
 * @property {boolean} wrap
 *
 * @typedef {ContainerAttributes|PropAttributes} XoneAttributes
 */

/**
 * Class to handle mappings attributes
 */
class XoneAttributesHandler {
	/**
	 * _instance
	 * @type {XoneAttributesHandler}
	 */
	static _instance;

	/**
	 * mapFormulaFunctions
	 * @type {Map<string,Function>}
	 */
	_mapFormulaFunctions = new Map();

	constructor() {
		if (XoneAttributesHandler._instance) return XoneAttributesHandler._instance;

		XoneAttributesHandler._instance = this;
	}

	/**
	 * Convert xone units to html units
	 * @param {any} value
	 * @param {boolean} [isLine]
	 * @param {boolean} [isLabelWidth]
	 * @param {string} [viewMode]
	 * @returns {string}
	 */
	convertUnits(value, isLine = false, isLabelwidth = false, viewMode = "") {
		// Si es un routerview vamos a devolver que ocupe el 100 % para que el cálculo de resizeo constante que hace el contents no provoque problemas
		// el tamaño se especifica en un frame
		if (viewMode === "routerview" || viewMode === "stacknavigation") return "100%";

		if (!value) return;

		if (value.toString().includes("px") || value.toString().includes("%")) return value;

		if (value.toString().includes("p")) return value.toString().replace("p", "px");

		if (isNaN(Number(value))) return value;

		const num = Number(value);
		if (num < 0) return num === -1 ? "grow" : "auto";

		if (isLine) return num + 0.5 + "em";

		return num === 0 ? `0${isLabelwidth ? "" : "px"}` : `${num}em`;
	}

	/**
	 * Get Paddings
	 * @param {Object} attributes
	 * @returns {Paddings}
	 */
	getPaddings(attributes) {
		return {
			top: this.convertUnits(attributes.tPadding ?? 0),
			right: this.convertUnits(attributes.rPadding ?? 0),
			bottom: this.convertUnits(attributes.bPadding ?? 0),
			left: this.convertUnits(attributes.lPadding ?? 0),
		};
	}
	/**
	 * Get Paddings
	 * @param {Object} attributes
	 * @returns {Paddings}
	 */
	getTabPaddings(attributes) {
		return {
			top: this.convertUnits(attributes.tabTPadding ?? 0),
			right: this.convertUnits(attributes.tabRPadding ?? 0),
			bottom: this.convertUnits(attributes.tabBPadding ?? 0),
			left: this.convertUnits(attributes.tabLPadding ?? 0),
		};
	}

	/**
	 * Resolve margins
	 * @param {Object} attributes
	 * @returns {Margins}
	 */
	getMargins(attributes) {
		/**
		 * Margins
		 * @type {Array<string>}
		 */
		let [top, right, bottom, left] = [attributes.tMargin ?? "0", attributes.rMargin ?? "0", attributes.bMargin ?? "0", attributes.lMargin ?? "0"].map(
			(e) => this.convertUnits(e)
		);
		if (attributes.node === "prop" && attributes.floatingTooltip && top === "0") top = "1rem";
		return { top, right, bottom, left };
	}

	/**
	 * Resolve borders
	 * @param {Object} attributes
	 * @returns {Borders}
	 */
	getBorders(attributes) {
		const [top, right, bottom, left] = [
			(attributes.textBorder ?? "true") === "true" && (attributes.textBorderTop ?? "true") === "true",
			(attributes.textBorder ?? "true") === "true" && (attributes.textBorderRight ?? "true") === "true",
			(attributes.textBorder ?? "true") === "true" && (attributes.textBorderBottom ?? "true") === "true",
			(attributes.textBorder ?? "true") === "true" && (attributes.textBorderLeft ?? "true") === "true",
		];

		return { top, right, bottom, left };
	}

	/**
	 * Get label align
	 * @param {*} align
	 * @returns {"flex-start"|"flex-end"|"center"|undefined}
	 */
	getLabelAlign(align) {
		if (!align) return;
		if (align?.toString().includes("left")) return "flex-start";
		if (align?.toString().includes("right")) return "flex-end";
		if (align?.toString().includes("center")) return "center";
	}

	/**
	 * Get prop align
	 * @param {string} align
	 * @param {string} [type]
	 * @param {string} [attribute]
	 * @returns {"center"|"end"|"start"|undefined}
	 */
	getPropAlign(align, type = "T", attribute = "") {
		if (!align && attribute !== "textAlign") {
			if (type.startsWith("N")) return "end";
		}
		if (align?.toString().includes("left")) return "start";
		if (align?.toString().includes("right")) return "end";
		if (align?.toString().includes("center")) return "center";
	}

	/**
	 * Resolve container align
	 * @param {Object} attributes
	 * @returns {ContainerAlign}
	 */
	getContainerAlign(attributes) {
		let align = (attributes.align ?? "").toString(),
			row = "",
			column = "";
		if (align.includes("left")) row = "flex-start";
		if (align.includes("right")) row = "flex-end";
		if (align.includes("top")) column = "flex-start";
		if (align.includes("bottom")) column = "flex-end";
		if (align.includes("center")) {
			if (align === "center") {
				row = "center";
				column = "center";
			} else if (row === "") row = "center";
			else column = "center";
			if (column === "" && align.replace("center", "").includes("center")) column = "center";
		}

		return { row, column };
	}

	/**
	 * Node visibility depending on the visibility bit
	 * @param {Object} attributes
	 * @param {Number} [visibilityBit]
	 */
	isNodeVisible() {
		//(attributes, visibilityBit = 1) {
		return true;
		// Comentado porque ya viene en el getLayout de appData
		// return (attributes.visible ?? 1) & Number(visibilityBit << 0 !== 0);
	}

	/**
	 * Calculate control size depending on container size and attribute width/height
	 * @param {string|Number|undefined} attributeSize
	 * @param {Number} containerSize
	 * @param {Number} scaleFactor
	 */
	getScaledSize(attributeSize, containerSize, scaleFactor) {
		let size;

		if (!attributeSize) return;

		if (attributeSize.toString().includes("px")) size = scaleFactor * Number(attributeSize.toString().replace("px", ""));
		else if (attributeSize.toString().includes("%")) size = (containerSize * Number(attributeSize.toString().replace("%", ""))) / 100;

		return size;
	}

	/**
	 * Calculate control position on container depending on size and scale
	 * @param {string} position
	 * @param {number} scaleFactor
	 * @returns {string}
	 */
	getScaledPosition(position, scaleFactor) {
		/**  @type {string|number} */
		let value = position;

		if (!value) return;

		if (value.toString().includes("px")) {
			value = value.replace("px", "");
			if (isNaN(Number(value))) return;
			return `${Number(value) * scaleFactor}px`;
		}
		return value;
	}

	/**
	 * Calculate and adjust height to parent container when height attribute is null or auto and height > containerHeight
	 * @param {PropAttributes} attributes
	 * @param {Ref<HTMLElement>} htmlElement
	 * @param {boolean} [isResized]
	 */
	fitHeightToContainer = async (attributes, htmlElement, isResized = false) => {
		// Si tenemos márgenes negativos, no hacemos el fit por problemas de renderizado del html
		if (attributes.margins?.top?.toString().startsWith("-") || attributes.margins?.bottom?.toString().startsWith("-")) return;

		try {
			if (attributes.height !== "auto" && attributes.width == "auto") return;
			await nextTick();

			/**
			 * frame / group parent element
			 * @type {HTMLElement}
			 */
			const containerElement = htmlElement.value?.parentElement?.parentElement?.parentElement;
			if (!containerElement) return;

			if (isResized || (containerElement.parentElement && containerElement.parentElement.classList.contains("xone-contents-row"))) {
				// If parent is contents row and  maxHeight is setted, reset and fit again
				if (htmlElement.value.style.maxHeight) {
					htmlElement.value.style.maxHeight = null; // button element
					htmlElement.value.parentElement.style.maxHeight = null; // prop element
					htmlElement.value.parentElement.parentElement.style.maxHeight = null; // row element
					return setTimeout(() => this.fitHeightToContainer(attributes, htmlElement), 100);
				}
			}

			if (!containerElement || containerElement.offsetHeight === 0) return;

			let maxHeight = `calc(${containerElement.offsetHeight}px + var(--margin-top) + var(--margin-bottom))`; // container width

			htmlElement.value.style.maxHeight = maxHeight; // button element
			// htmlElement.value.style.objectFit = "contain";
			htmlElement.value.parentElement.style.maxHeight = maxHeight; // prop element
			htmlElement.value.parentElement.parentElement.style.maxHeight = maxHeight; // row element
		} catch { }
	};

	/**
	 * Calculate and adjust width to parent container when width attribute is null or auto and width > containerWidth
	 * @param {PropAttributes} attributes
	 * @param {Ref<HTMLElement>} htmlElement
	 * @param {boolean} [isResized]
	 */
	fitWidthToContainer = async (attributes, htmlElement, isResized = false) => {
		// Si tenemos márgenes negativos, no hacemos el fit por problemas de renderizado del html
		if (attributes.margins?.left?.toString().startsWith("-") || attributes.margins?.right?.toString().startsWith("-")) return;

		try {
			if (attributes.height === "auto" && attributes.width !== "auto") return;

			await nextTick();

			/**
			 * frame / group parent element
			 * @type {HTMLElement}
			 */
			const containerElement = htmlElement.value?.parentElement?.parentElement?.parentElement;

			if (!containerElement) return;

			// If parent is contents row and  maxWidth is setted, reset and fit again
			if (isResized || (containerElement.parentElement && containerElement.parentElement.classList.contains("xone-contents-row"))) {
				if (htmlElement.value.style.maxWidth) {
					htmlElement.value.style.maxWidth = null; // button element
					htmlElement.value.parentElement.style.maxWidth = null; // prop element
					htmlElement.value.parentElement.parentElement.style.maxWidth = null; // row element
					return setTimeout(() => this.fitWidthToContainer(attributes, htmlElement), 100);
				}
			}

			if (!containerElement || containerElement.offsetWidth === 0) return;

			let maxWidth = `calc(${containerElement.offsetWidth}px + var(--margin-right) + var(--margin-left))`; // container width

			htmlElement.value.style.maxWidth = maxWidth; // button element
			// htmlElement.value.style.objectFit = "contain";
			htmlElement.value.parentElement.style.maxWidth = maxWidth; // prop element
			htmlElement.value.parentElement.parentElement.style.maxWidth = maxWidth; // row element
		} catch { }
	};

	/**
	 * Calculate font size
	 * @param {string} fontSize
	 */
	getFontSize(fontSize) {
		if (fontSize && !isNaN(fontSize) && _isScaleFontsize) fontSize = Number(fontSize) + _webFontFactor;
		const u = /*(_resolutionHeight ?? -1) === -1 && (_resolutionWidth ?? -1) === -1 ? 1080 :*/ window.innerWidth > window.innerHeight ? "vh" : "vw";
		if (!fontSize) fontSize = "8";
		if (isNaN(Number(fontSize))) return fontSize;
		return `calc(${Number(fontSize) + 3}px + .5${u})`;
	}

	/**
	 * Get prop type
	 * @param {Object} attributes
	 */
	getPropType(attributes) {
		let type = attributes.type ?? "T";
		if (type === "T") {
			if ((attributes.mask ?? "") == "Hh#:#Mm") type = "TT";
		}
		return type;
	}

	/**
	 * Resolve image attribute
	 * @param {string} img
	 */
	getImage(img) {
		if (!img) return null;
		if (img) img = img.replace("./icons/", "").replace("##APP##", "").replace("##APPPATH##", "").replace("\\icons\\", "");

		return img;
	}

	/**
	 * Resolve floating / top / left attributes
	 * @param {*} attributes
	 * @returns {Floating}
	 */
	getFloating(attributes) {
		if (!attributes.floating || !attributes.top || !attributes.left || attributes.floating !== "true") return { floating: false };
		const top = this.convertUnits(attributes.top);
		const left = this.convertUnits(attributes.left);
		return { floating: true, top, left };
	}

	/**
	 * getAnimationIn
	 * @param {string} animation
	 */
	getAnimationIn(animation) {
		switch (animation) {
			case "##RIGHT_IN##":
				return "slideLeft .3s";
			case "##LEFT_IN##":
				return "slideRight .3s";
			case "##PUSH_DOWN_IN##":
				return "slideDown .3s";
			case "##PUSH_IN##":
				return "slideUp .3s";
			case "##ALPHA_IN##":
				return "fadeIn .3s";
			case "##ZOOM_IN##":
				return "zoomIn .3s";
			default:
				return;
		}
	}

	/**
	 * Border Corner Radius to html border-radius
	 * @param {*} attributes
	 * @param {boolean} [isTab]
	 * @returns {string}
	 */
	getBorderCornerRadius(attributes, isTab = false) {
		let borderRadius = "";
		if (!isTab) {
			borderRadius += (attributes.borderCornerRadiusTopLeft || attributes.borderCornerRadius || "0") + "px ";
			borderRadius += (attributes.borderCornerRadiusTopRight || attributes.borderCornerRadius || "0") + "px ";
			borderRadius += (attributes.borderCornerRadiusBottomRight || attributes.borderCornerRadius || "0") + "px ";
			borderRadius += (attributes.borderCornerRadiusBottomLeft || attributes.borderCornerRadius || "0") + "px";
		} else {
			borderRadius += (attributes.tabBorderCornerRadiusTopLeft || attributes.tabBorderCornerRadius || "0") + "px ";
			borderRadius += (attributes.tabBorderCornerRadiusTopRight || attributes.tabBorderCornerRadius || "0") + "px ";
			borderRadius += (attributes.tabBorderCornerRadiusBottomRight || attributes.tabBorderCornerRadius || "0") + "px ";
			borderRadius += (attributes.tabBorderCornerRadiusBottomLeft || attributes.tabBorderCornerRadius || "0") + "px";
		}
		return borderRadius;
	}

	/**
	 * Attributes for prop
	 * @param {Object} attributes
	 * @param {Number} visibilityBit
	 * @returns {PropAttributes}
	 */
	getPropAttributes(attributes, visibilityBit = 1) {
		return {
			align: attributes.type === "B" ? this.getContainerAlign(attributes) : this.getPropAlign(attributes.align, attributes.type),
			allowedExtensions: attributes.allowedExtensions,
			autosave: attributes.autosave === "true",
			autoslideDelay: !isNaN(Number(attributes.autoslideDelay)) && Number(attributes.autoslideDelay),
			barColor: attributes.barColor ?? "gray",
			bgColor: attributes.bgColor,
			bit: attributes.bit,
			bMargin: attributes.bMargin,
			borderColor: attributes.borderColor || attributes.textForeColor || attributes.foreColor || "black",
			borderCornerRadius: this.getBorderCornerRadius(attributes),
			borders: this.getBorders(attributes),
			borderWidth: `${attributes.borderWidth ?? 1}px`,
			breadcrumbFontSize: this.getFontSize(attributes.breadcrumbFontSize),
			breadcrumbForeColor: attributes.breadcrumbForeColor,
			breadcrumbSelectedFontSize: this.getFontSize(attributes.breadcrumbSelectedFontSize),
			breadcrumbSelectedForeColor: attributes.breadcrumbSelectedForeColor,
			buttonOption: attributes.buttonOption,
			calendarViewMode: attributes.calendarViewMode,
			cellBgColor: attributes.cellBgColor,
			cellBorderColor: attributes.cellBorderColor,
			cellEvenColor: attributes.cellEvenColor ?? "transparent",
			cellForeColor: attributes.cellForeColor,
			cellOddColor: attributes.cellOddColor ?? "transparent",
			cellOtherMonthBgColor: attributes.cellOtherMonthBgColor,
			cellSelectedBgColor: attributes.cellSelectedBgColor,
			cellSelectedBorderColor: attributes.cellSelectedBorderColor,
			cellSelectedForeColor: attributes.cellSelectedForeColor,
			circleRadius: attributes.circleRadius === "true",
			clusterMarkers: attributes.clusterMarkers === "true",
			contents: attributes.contents,
			disableEdit: attributes.disableEdit,
			disableVisible: attributes.disableVisible,
			editInRow: attributes.editInRow === "true",
			elevation: attributes.elevation ?? 0,
			fieldSize: this.convertUnits(attributes.fieldSize ?? 12),
			fixedText: attributes.fixedText === "true",
			floating: this.getFloating(attributes),
			floatingTooltip: attributes.floatingTooltip === "true",
			fluidLoad: attributes.fluidLoad === "true",
			fontBold: attributes.fontBold === "true",
			fontSize: this.getFontSize(attributes.fontSize),
			// tooltipFontSize: this.getFontSize(attributes.fontSize, "-2px"),
			foreColor: attributes.foreColor,
			foreColorDisabled: attributes.foreColorDisabled,
			galleryColumns: Number(attributes.galleryColumns ?? 1),
			hashType: attributes.hashType,
			height: this.convertUnits(attributes.height, false, false, attributes.viewMode),
			href: attributes.href,
			image: this.getImage(attributes.image),
			imgChecked: this.getImage(attributes.imgChecked),
			imgCheckedDisabled: this.getImage(attributes.imgCheckedDisabled),
			imgHeight: this.convertUnits(attributes.imgHeight),
			imgUnchecked: this.getImage(attributes.imgUnchecked),
			imgUncheckedDisabled: this.getImage(attributes.imgUncheckedDisabled),
			imgWidth: this.convertUnits(attributes.imgWidth),
			interval: attributes.interval && !isNaN(Number(attributes.interval)) ? Number(attributes.interval) : 10,
			isRadio: attributes.checkType === "radio" || attributes.viewMode === "radio",
			isVisible: this.isNodeVisible(attributes, visibilityBit),
			keepAspectRatio: attributes.keepAspectRatio === "true",
			labelAlign: this.getLabelAlign(attributes.align),
			labelBox: attributes.labelBox === "true",
			labelWidth: attributes.type === "TL" && attributes.width ? "100%" : this.convertUnits(attributes.labelwidth ?? 8, false, true),
			lines: this.convertUnits(attributes.lines ?? "auto", true),
			linkedField: attributes.linkedField,
			linkedTo: attributes.linkedTo,
			lMargin: attributes.lMargin,
			locked: attributes.locked === "true",
			lockedOnScript: (attributes.lockedOnScript ?? "true") === "true",
			margins: this.getMargins(attributes),
			method: attributes.method,
			minHeight: this.convertUnits(attributes.minHeight),
			minValue: attributes.minValue && !isNaN(Number(attributes.minValue)) ? Number(attributes.minValue) : 0,
			maxValue: attributes.maxValue && !isNaN(Number(attributes.maxValue)) ? Number(attributes.maxValue) : 100,
			minWidth: this.convertUnits(attributes.minWidth),
			startAngle: attributes.startAngle && !isNaN(Number(attributes.startAngle)) ? Number(attributes.startAngle) : 0,
			endAngle: attributes.endAngle && !isNaN(Number(attributes.endAngle)) ? Number(attributes.endAngle) : 270,
			offset: attributes.offset && !isNaN(Number(attributes.offset)) ? Number(attributes.offset) : 15,
			multiLine: Number(attributes.lines ?? 1) !== 1 || attributes.height === "-1" || attributes.height === "-2",
			name: attributes.name,
			node: attributes.node,
			objectFit: attributes.scaleType !== "center_crop" ? null : attributes.keepAspectRatio === "true" ? "cover" : "fill",
			onClick: attributes.onClick,
			onLongPress: attributes.onLongPress,
			onEditorAction: attributes.onEditorAction,
			onFocusChanged: attributes.onFocusChanged,
			onScroll: attributes.onScroll,
			onTextChanged: attributes.onTextChanged,
			paddings: this.getPaddings(attributes),
			paginationSize: !isNaN(attributes.paginationSize) ? Number(attributes.paginationSize) : undefined,
			postOnchange: attributes.postOnchange,
			radioGroup: attributes.radioGroup,
			readOnly: (attributes.readOnly ?? "true") === "true",
			rMargin: attributes.rMargin,
			rowsPerPage: attributes.rowsPerPage,
			showInline: attributes.showInline === "true",
			showInlineKeyboard: attributes.showInlineKeyboard === "true",
			showInMarker: attributes.showInMarker === "true",
			showPois: (attributes.showPois ?? "true") === "true",
			size: attributes.size,
			startFromBottom: attributes.startFromBottom === "true",
			textAlign: this.getPropAlign(attributes.textAlign, attributes.type, "textAlign"),
			textBgColor: attributes.textBgColor,
			textBgColorDisabled: attributes.textBgColorDisabled,
			textBorder: attributes.textBorder,
			textBorderBottom: attributes.textBorderBottom,
			textBorderLeft: attributes.textBorderLeft,
			textBorderRight: attributes.textBorderRight,
			textBorderTop: attributes.textBorderTop,
			textOverflow: attributes.textOverflow,
			textFontSize: this.getFontSize(attributes.textFontSize),
			textForeColor: attributes.textForeColor || attributes.foreColor,
			textForeColorDisabled: attributes.textForeColorDisabled,
			title: attributes.title,
			tMargin: attributes.tMargin,
			tooltip: attributes.tooltip,
			tooltipColor: attributes.tooltipColor,
			tooltipForeColor: attributes.tooltipForeColor,
			type: this.getPropType(attributes),
			undoButton: (attributes.undoButton ?? "false") === "true",
			viewMode: attributes.type === "Z" && attributes.orientation === "horizontal" ? "slideview" : attributes.viewMode,
			weekdaysLongname: attributes.weekdaysLongname === "true",
			width: this.convertUnits(attributes.width, false, false, attributes.viewMode),
			wrap: attributes.wrap === "true",
		};
	}

	/**
	 * Attributes for containers
	 * @param {Object} attributes
	 * @returns {ContainerAttributes}
	 */
	getContainerAttributes(attributes) {
		return {
			align: this.getContainerAlign(attributes),
			animationIn: this.getAnimationIn(attributes.animationIn),
			animationOut: attributes.animationOut, //this.getAnimation(attributes.animationOut),
			bgColor: attributes.bgColor,
			bMargin: attributes.bMargin,
			borderColor: attributes.borderColor ?? "black",
			borderCornerRadius: this.getBorderCornerRadius(attributes),
			borders: this.getBorders(attributes),
			borderWidth: `${attributes.borderWidth ?? 1}px`,
			cellEvenColor: attributes.cellEvenColor ?? "transparent",
			cellOddColor: attributes.cellOddColor ?? "transparent",
			cellSelectedBgColor: attributes.cellSelectedBgColor,
			disableVisible: attributes.disableVisible,
			drawerOrientation: attributes.drawerOrientation,
			elevation: attributes.elevation ?? 0,
			fixed: attributes.fixed ?? "",
			floating: this.getFloating(attributes),
			foreColor: attributes.foreColor,
			framebox: attributes.framebox === "true",
			groupSwipe: attributes.groupSwipe === "true",
			height: this.convertUnits(attributes.height),
			href: attributes.href,
			id: attributes.id,
			image: this.getImage(attributes.image),
			keepAspectRatio: attributes.keepAspectRatio === "true",
			lMargin: attributes.lMargin,
			loadAll: attributes.loadAll === "true",
			margins: this.getMargins(attributes),
			minHeight: this.convertUnits(attributes.minHeight),
			minWidth: this.convertUnits(attributes.minWidth),
			name: attributes.name,
			newLine: (attributes.newLine ?? "true") === "true",
			node: attributes.node,
			noTab: attributes.noTab === "true",
			onClick: attributes.onClick,
			onLongPress: attributes.onLongPress,
			onFocus: attributes.onFocus,
			onScroll: attributes.onScroll,
			orientation: attributes.orientation ?? "",
			paddings: this.getPaddings(attributes),
			rMargin: attributes.rMargin,
			scroll: attributes.scroll === "true",
			scrollOrientation: attributes.scrollOrientation ?? "vertical",
			tabBgColor: attributes.tabBgColor ?? "white",
			tabBorderCornerRadius: this.getBorderCornerRadius(attributes, true),
			tabFontSize: this.getFontSize(attributes.tabFontSize),
			tabForecolor: attributes.tabForecolor ?? "black",
			tabPaddings: this.getTabPaddings(attributes),
			tabSelectedBgcolor: attributes.tabSelectedBgcolor,
			tabSelectedFontsize: this.getFontSize(attributes.tabSelectedFontsize),
			tabSelectedForecolor: attributes.tabSelectedForecolor ?? "#3273dc",
			tMargin: attributes.tMargin,
			viewMode: attributes.viewMode,
			width: this.convertUnits(attributes.width),
			wrap: attributes.wrap === "true",
		};
	}

	/**
	 * Execute method node (method, onfocus ...)
	 * @param {string} method
	 * @param {XoneDataObject} xoneDataObject
	 * @param {string} [propName]
	 * @returns {Promise<boolean>}
	 */
	async executeMethod(method, xoneDataObject, propName) {
		try {
			const lowerCaseMethod = method?.trim().toLowerCase();

			// Remove 'javascript:' if  exists
			if (lowerCaseMethod.startsWith("javascript:")) method = method.trim().substring(11);

			//
			// Is not executeNode
			if (!lowerCaseMethod.includes("executenode")) {
				// Refresh
				if (lowerCaseMethod.startsWith("refresh")) {
					if (!propName) return;
					return xoneDataObject.DoRunScriptAsync(`ui.refresh('${propName}')`);
				}
				// RunScript
				return xoneDataObject.DoRunScriptAsync(method);
			}

			//
			// Is executeNode

			// Parse method
			let params;
			method = method.substring(method.indexOf("(")).replace("(", "").replace(")", "");

			// with params
			if (method.includes("(") && method.includes(")")) {
				params = method.substring(method.indexOf("("));
				method = method.replace(params, "");
				params = params.replace("(", "").replace(")", "").split(",");
				params = params.map((param) =>
					!param.toString().startsWith("'") &&
						!param.toString().startsWith('"') &&
						!param.toString().startsWith("'") &&
						!param.toString().startsWith('"')
						? `'${param}'`
						: param
				);
				await xoneDataObject.ExecuteNode(method, ...params);
			}
			// without params
			else {
				await xoneDataObject.ExecuteNode(method);
			}
		} catch (ex) {
			console.error(ex);
		}
	}

	/**
	 * Evaluate disableedit / disablevisible
	 * @param {string} formula
	 * @param {Object} dataModel
	 */
	evalFormula(formula, dataModel) {
		try {
			/**
			 * Eval Function
			 * @type {Function}
			 */
			let fFunction = this._mapFormulaFunctions.get(formula);

			// Function exist -> execute and return result
			if (fFunction) return fFunction(dataModel);

			// Function does not exist -> parse formula (from XOne to javascript) and create function
			const operators = [
				// xone conditions
				{ from: "+", to: " + " },
				{ from: "-", to: " - " },
				{ from: "/", to: " / " },
				{ from: "*", to: " * " },
				{ from: "&", to: " + " },
				{ from: " and ", to: " && " },
				{ from: " or ", to: " || " },
				{ from: "<>", to: " != " },
				{ from: ">", to: " > " },
				{ from: "<", to: " < " },
				{ from: "=", to: " == " },
			];
			let st = formula;
			// Eliminamos espacios entre operadores
			do {
				operators.forEach(({ from }) => (st = st.replace(" " + from, from)));
				operators.forEach(({ from }) => (st = st.replace(from + " ", from)));
			} while (operators.some(({ from }) => st.includes(" " + from) || st.includes(from + " ")));

			const replacedOperators = [];

			// returns if formula includes an oper
			const replaceOperator = (operator) => {
				let index;
				while ((index = st.toLowerCase().indexOf(operator.from)) !== -1) {
					replacedOperators.push({
						index,
						oper: operator.to,
					});
					st = st.replace(
						st.substr(index, operator.from.length),
						Array(operator.from.length + 1).join("~") // Replace operator's chars with ~
					);
				}
			};

			// Parse opers
			operators.forEach(replaceOperator);

			// Clear extra chars
			while (st.includes("~~")) st = st.replace("~~", "~");

			// Sort replaced operators by index
			const order = replacedOperators.map((e) => e.index).sort((a, b) => a - b);

			// Generate the final formula to be evaluated
			let stFinal = "";
			let i = 0;
			st.split("~").forEach((e) => {
				if (stFinal !== "" && i < order.length) {
					// Add Operator
					stFinal += replacedOperators.find((e) => e.index === order[i]).oper;
					i++;
				}
				let prop = e.trim().split("(").join("").split(")").join("");
				if (Object.keys(dataModel).find((e) => e === prop)) {
					// Replace prop
					return (stFinal += e.replace(prop, `self['${prop.trim()}']`));
				}
				stFinal += e;
			});

			// Replace macros ##FLD_ ##
			if (stFinal.includes("##FLD_")) stFinal = stFinal.replace(/##FLD_/g, "self['").replace(/##/g, "']");

			// create function
			this._mapFormulaFunctions.set(
				formula,
				(fFunction = Function(
					"self",
					`try{return ${stFinal}}catch(ex){console.error("Error evaluating formula:", "'${stFinal}'");console.warn(ex);throw "Error evaluating formula";}`
				))
			);
			// eval formula
			return fFunction(dataModel);
		} catch (ex) {
			console.error("Error evaluating formula", formula, ex);
		}
	}

	/**
	 * Observe changes in attributes model and data model with ##FLD_ fields and fetch them to attributes
	 * @param {Ref<XoneAttributes>} attributes
	 * @param {Ref<Object>} attributesModel
	 * @param {Ref<XoneAttributes>} dataModel
	 */
	watchAttributes(attributes, attributesModel, dataModel) {
		/**
		 * resolve Attribute
		 * @param {string} e Attribute
		 */
		const resolveAttr = (e) => {
			if (e === "disableVisible" || e === "disableEdit") return (attributes.value[e] = attributesModel.value[e]);

			let st = attributesModel.value[e];
			while (st?.toString().includes("##FLD")) {
				// Foreign key
				const foreignKey = st.substring(st.indexOf("##FLD_") + 6, st.indexOf("##", st.indexOf("##") + 6));
				// Resolve ##FLD_
				if (foreignKey && Object.keys(dataModel).includes(foreignKey)) st = st.replace(`##FLD_${foreignKey}##`, dataModel[foreignKey]);
				else
					return console.warn(
						`Could not resolve ##FLD_ in ${attributes.value.node} | ${attributes.value.name} -> ${e}="${attributesModel.value[e]}"`
					);
			}
			// Resolve attribute
			const newAttrs =
				attributesModel.value.node === "prop"
					? this.getPropAttributes({ ...attributes.value, [e]: st })
					: this.getContainerAttributes({ ...attributes.value, [e]: st });
			// Si el valor del atributo lo convierto a un objeto (margins, paddings y borders), lo asigno
			if (e.toLowerCase().contains("padding")) {
				attributes.value.paddings = newAttrs.paddings;
				if (attributesModel.value.node === "coll") attributes.value.tabPaddings = newAttrs.tabPaddings;
			} else if (e.toLowerCase().contains("margin")) attributes.value.margins = newAttrs.margins;
			else if (e.toLowerCase().contains("border")) attributes.value.borders = newAttrs.borders;
			// Asignamos el valor del atributo modificado
			attributes.value[e] = newAttrs[e];
		};
		// Watch Attributes Model
		Object.keys(attributesModel.value).forEach((e) => {
			watch(
				() => attributesModel.value[e],
				(newValue, oldValue) => {
					// Si es un objeto, comparamos que realmente haya cambiado algún valor...
					if (typeof newValue === "object") {
						if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) resolveAttr(e);
					} else {
						resolveAttr(e);
					}
				}
			);
		});
		// Watch Data Model
		Object.keys(dataModel).forEach((dMKey) => {
			const resolveMapModels = () =>
				Object.entries(attributesModel.value).forEach(([attrKey, attrValue]) => {
					if (typeof attrValue === "string" && attrValue.includes(`##FLD_${dMKey}##`)) {
						resolveAttr(attrKey);
					}
				});
			watch(
				() => dataModel[dMKey],
				() => resolveMapModels()
			);
			resolveMapModels();
		});
	}

	/**
	 * executeBindedEvent
	 * @param {XoneDataObject} xoneDataObject
	 * @param {Object} attributes
	 * @param {string} type
	 * @param {Object} options
	 */
	async executeBindedEvent(xoneDataObject, attributes, type, options) {
		try {
			const objView = getView(xoneDataObject);
			if (!objView) return;
			const event = objView.bindedEvents?.find((e) => e.fieldName === attributes.name && e.eventName === type);

			if (event && event.action) {
				if (typeof event.action === "function") {
					// Add params
					const params = {
						target: attributes.name,
						objItem: attributes.name,
						data: event.params,
					};

					Object.keys(options).forEach((key) => (params[key] = options[key]));
					// Execute function
					await event.action(params);
				}
				// TODO: revisar, creo que se ejecuta un nodo pero realmente no tengo ni idea de lo que debe de hacer eso...
				else xoneDataObject.DoRunScriptAsync(event.action).catch(console.error);
			}
		} catch (ex) {
			console.error(ex);
		}
	}

	/**
	 * onScrollEvent
	 * @param {HTMLDivElement} element
	 * @param {XoneAttributes} attributes
	 * @param {XoneDataObject} xoneDataObject
	 */
	async onScrollEvent(element, attributes, xoneDataObject) {
		if (!element) return;
		const { scrollTop, scrollLeft } = element;
		if (attributes.onScroll) {
			await xoneDataObject.DoRunScriptAsync(
				`let e = { target: '${attributes.name}',objItem: '${attributes.name}',dy: ${scrollTop},dx: ${scrollLeft} };
        ${attributes.onScroll}`
			);
		}
		// Execute binded event in script
		await this.executeBindedEvent(xoneDataObject, attributes.name, "onscroll", {
			dy: scrollTop,
			dx: scrollLeft,
		});
	}

	/**
	 * onClick
	 * @param {XoneAttributes} attributes
	 * @param {XoneDataObject} xoneDataObject
	 * @param {import("./AppDataHandler").Objectinfo} objectInfo
	 * @param {bool} [isLongPress]
	 */
	async onClick(attributes, xoneDataObject, objectInfo, isLongPress = false) {
		//
		// Execute method
		if (!isLongPress) {
			const method = attributes.method;
			if (method) await xoneAttributesHandler.executeMethod(method, xoneDataObject);
		}

		//
		// Execute onclick
		const eventScript = isLongPress ? attributes?.onLongPress : attributes?.onClick;
		try {
			if (eventScript)
				await xoneDataObject.DoRunScriptAsync(
					`let e = { target: '${attributes.name}',objItem: '${attributes.name}' };
              ${eventScript}`
				);
		} catch (ex) {
			console.error(ex);
		}

		//
		// Execute binded event in script
		await xoneAttributesHandler.executeBindedEvent(xoneDataObject, attributes, "onclick", {});

		//
		// Is Custom Msgbox
		if (objectInfo.isMsgBox && attributes.buttonOption)
			objectInfo.onMsgBoxOptionSelected && objectInfo.onMsgBoxOptionSelected(attributes.buttonOption);
	}

	/**
	 * Realizamos el animation Out del frame
	 * @param {HTMLDivElement} element
	 * @param {ContainerAttributes} attributes
	 */
	doAnimationOut(element, attributes) {
		switch (attributes.animationOut) {
			case "##LEFT_OUT##":
				element.animate({ transform: `translateX(-200px)` }, { duration: 300 });
				break;
			case "##RIGHT_OUT##":
				element.animate({ transform: `translateX(200px)` }, { duration: 300 });
				break;
			case "##ALPHA_OUT##":
				element.animate({ opacity: 0 }, { duration: 300 });
				break;
			case "##PUSH_OUT##":
				element.animate({ transform: `translateY(-200px)` }, { duration: 300 });
				break;
			case "##PUSH_DOWN_OUT##":
				element.animate({ transform: `translateY(200px)` }, { duration: 300 });
				break;
			case "##ZOOM_OUT##":
				element.animate({ transform: `scale(0)` }, { duration: 300 });
				break;
		}
	}
}

export const xoneAttributesHandler = new XoneAttributesHandler();
